<template>
    <div class="roadFront2">
        <el-col >
            <!--        <el-dropdown @command="addmembers" ref="init">-->
            <!--            <el-button type="primary" size="mini">添加流程<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            <!--            </el-button>-->
            <!--            <el-dropdown-menu slot="dropdown">-->
            <!--                <el-dropdown-item :disabled="item.disabled" v-for="(item,index) in tagList" :key="index" :command="item.MC">{{item.MC}}</el-dropdown-item>-->
            <!--            </el-dropdown-menu>-->
            <!--        </el-dropdown>-->




            <el-form :disabled="isLock" :inline="true" :model="formInline" class="demo-form-inline flow">
                <table cellspacing="0 " cellpadding="0 " class="el-table--border " style="padding-bottom: 24px">
                    <thead>
                    <tr>
                        <th width="53 ">序号</th>
                        <th colspan="3" width="400">项目阶段</th>
                        <th width="80">建议周期 (日)</th>
                        <th width="160 ">计划完成时间</th>
                        <th width="160 ">实际完成时间</th>
                        <th width="143 ">审批部门</th>
                        <th width="100 ">牵头部门</th>
                        <th width="100 ">责任部门</th>
                        <th width="150 ">备注</th>
                        <th width="160 ">附件</th>
                        <th width="150 ">操作</th>
                    </tr>
                    </thead>
                    <tr>
                        <td rowspan="11 ">1</td>
                        <td rowspan="11 " class="ver">工可研阶段</td>
                        <td width="160 " colspan="2" >招标采购</td>
                        <td width="80 ">{{listData[0].jyzq ? listData[0].jyzq :'' }}</td>
                        <td width="100 ">{{listData[0].jhwcsj?listData[0].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[0].sfcq ? listData[0].sfcq : ''">{{listData[0].pfsj}}</td>
                        <td :class="listData[0].shbm ? '' : 'xx'">{{listData[0].shbm ? listData[0].shbm :'' }}</td>
                        <td rowspan="11">厅综合规划处宇文翀</td>
                        <td rowspan="29">（责任部门为省公路中心（省建设中心）、地方政府；责任人为部门一把手或县长）</td>
                        <td width="160 ">{{listData[0].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[0].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[0].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[0].fj.length>0?'success':'danger'" size="mini">已上传{{listData[0].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[0],0,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">工可研方案审查</td>
                        <td width="80 ">{{listData[1].jyzq}}</td>
                        <td width="100 ">{{listData[1].jhwcsj?listData[1].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[1].sfcq ? listData[1].sfcq : ''">{{listData[1].pfsj}}</td>
                        <td :class="listData[1].shbm ? '' : 'xx'">{{listData[1].shbm ? listData[1].shbm :'' }}</td>
                        <td width="160 ">{{listData[1].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[1].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[1].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[1].fj.length>0?'success':'danger'" size="mini">已上传{{listData[1].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[1],1,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">工可研报告上报	</td>
                        <td width="80 ">{{listData[2].jyzq}}</td>
                        <td width="100 ">{{listData[2].jhwcsj?listData[2].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[2].sfcq ? listData[2].sfcq : ''">{{listData[2].pfsj}}</td>
                        <td :class="listData[2].shbm ? '' : 'xx'">{{listData[2].shbm ? listData[2].shbm :'' }}</td>
                        <td width="160 ">{{listData[2].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[2].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[2].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[2].fj.length>0?'success':'danger'" size="mini">已上传{{listData[2].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[2],2,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">工可研联合评审	</td>
                        <td width="80 ">{{listData[3].jyzq}}</td>
                        <td width="100 ">{{listData[3].jhwcsj?listData[3].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[3].sfcq ? listData[3].sfcq : ''">{{listData[3].pfsj}}</td>
                        <td :class="listData[3].shbm ? '' : 'xx'">{{listData[3].shbm ? listData[3].shbm :'' }}</td>
                        <td width="160 ">{{listData[3].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[3].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[3].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[3].fj.length>0?'success':'danger'" size="mini">已上传{{listData[3].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[3],3,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="40 " rowspan="4" colspan="1">用地预审和规划选址意见 </td>
                        <td width="160 " >预审用地组卷上报	</td>
                        <td width="80 ">{{listData[4].jyzq}}</td>
                        <td width="100 ">{{listData[4].jhwcsj?listData[4].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[4].sfcq ? listData[4].sfcq : ''">{{listData[4].pfsj}}</td>
                        <td :class="listData[4].shbm ? '' : 'xx'">{{listData[4].shbm ? listData[4].shbm :'' }}</td>
                        <td width="160 ">{{listData[4].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[4].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[4].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[4].fj.length>0?'success':'danger'" size="mini">已上传{{listData[4].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[4],4,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " >省厅初审上报</td>
                        <td width="80 ">{{listData[5].jyzq}}</td>
                        <td width="100 ">{{listData[5].jhwcsj?listData[5].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[5].sfcq ? listData[5].sfcq : ''">{{listData[5].pfsj}}</td>
                        <td :class="listData[5].shbm ? '' : 'xx'">{{listData[5].shbm ? listData[5].shbm :'' }}</td>
                        <td width="160 ">{{listData[5].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[5].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[5].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[5].fj.length>0?'success':'danger'" size="mini">已上传{{listData[5].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[5],5,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " >⭐用地预审批复</td>
                        <td width="80 ">{{listData[6].jyzq}}</td>
                        <td width="100 ">{{listData[6].jhwcsj?listData[6].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[6].sfcq ? listData[6].sfcq : ''">{{listData[6].pfsj}}</td>
                        <td >{{listData[6].shbm ? listData[6].shbm :'自然资源部/省自然资源厅' }}</td>
                        <td width="160 ">{{listData[6].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[6].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[6].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[6].fj.length>0?'success':'danger'" size="mini">已上传{{listData[6].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[6],6,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " >⭐规划选址意见书</td>
                        <td width="80 ">{{listData[7].jyzq}}</td>
                        <td width="100 ">{{listData[7].jhwcsj?listData[7].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[7].sfcq ? listData[7].sfcq : ''">{{listData[7].pfsj}}</td>
<!--                        <td class="blue">（地方自然资源局）</td>-->
                        <td :class="listData[7].shbm ? '' : 'blue'">{{listData[7].shbm ? listData[7].shbm :'（地方自然资源局）' }}</td>

                        <td width="160 ">{{listData[7].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[7].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[7].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[7].fj.length>0?'success':'danger'" size="mini">已上传{{listData[7].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[7],7,'')">编辑</el-button>
                        </td>
                    </tr>

                    <tr>
                        <td width="40 " rowspan="2" colspan="1">资金承诺</td>
                        <td width="160 " >政府资金承诺</td>
                        <td width="80 ">{{listData[8].jyzq}}</td>
                        <td width="100 ">{{listData[8].jhwcsj?listData[8].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[8].sfcq ? listData[8].sfcq : ''">{{listData[8].pfsj}}</td>
                        <td :class="listData[8].shbm ? '' : 'blue'">{{listData[8].shbm ? listData[8].shbm :'（地方财政局）' }}</td>
                        <td width="160 ">{{listData[8].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[8].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[8].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[8].fj.length>0?'success':'danger'" size="mini">已上传{{listData[8].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[8],8,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " >⭐债务风险评估</td>
                        <td width="80 ">{{listData[9].jyzq}}</td>
                        <td width="100 ">{{listData[9].jhwcsj?listData[9].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[9].sfcq ? listData[9].sfcq : ''">{{listData[9].pfsj}}</td>
                        <td :class="listData[9].shbm ? '' : 'blue'">{{listData[9].shbm ? listData[9].shbm :'（省财政厅）' }}</td>
                        <td width="160 ">{{listData[9].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[9].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[9].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[9].fj.length>0?'success':'danger'" size="mini">已上传{{listData[9].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[9],9,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 "  colspan="2">⭐工可研批复</td>
                        <td width="80 ">{{listData[10].jyzq}}</td>
                        <td width="100 ">{{listData[10].jhwcsj?listData[10].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[10].sfcq ? listData[10].sfcq : ''">{{listData[10].pfsj}}</td>
                        <td >{{listData[10].shbm ? listData[10].shbm :'省发改委' }}</td>

                        <td width="160 ">{{listData[10].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[10].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[10].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[10].fj.length>0?'success':'danger'" size="mini">已上传{{listData[10].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[10],10,'')">编辑</el-button>
                        </td>
                    </tr>

                    <tr>
                        <td rowspan="4">2</td>
                        <td rowspan="4 " width="37 " class="ver">PPP准备阶段</td>
                        <td width="160 " colspan="2">招标采购</td>
                        <td width="80 ">{{listData[11].jyzq}}</td>
                        <td width="100 ">{{listData[11].jhwcsj?listData[11].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[11].sfcq ? listData[11].sfcq : ''">{{listData[11].pfsj}}</td>
                        <td :class="listData[11].shbm ? '' : 'xx'">{{listData[11].shbm ? listData[11].shbm :'' }}</td>

                        <td rowspan="18">厅建设管理处李宁</td>
                        <td width="160 ">{{listData[11].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[11].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[11].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[11].fj.length>0?'success':'danger'" size="mini">已上传{{listData[11].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[11],11,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">“两评一案”编制	</td>
                        <td width="80 ">{{listData[12].jyzq}}</td>
                        <td width="100 ">{{listData[12].jhwcsj?listData[12].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[12].sfcq ? listData[12].sfcq : ''">{{listData[12].pfsj}}</td>
                        <td :class="listData[12].shbm ? '' : 'xx'">{{listData[12].shbm ? listData[12].shbm :'' }}</td>

                        <td width="160 ">{{listData[12].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[12].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[12].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[12].fj.length>0?'success':'danger'" size="mini">已上传{{listData[12].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[12],12,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">“两评”批复</td>
                        <td width="80 ">{{listData[13].jyzq}}</td>
                        <td width="100 ">{{listData[13].jhwcsj?listData[13].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[13].sfcq ? listData[13].sfcq : ''">{{listData[13].pfsj}}</td>
                        <td :class="listData[13].shbm ? '' : 'blue'">{{listData[13].shbm ? listData[13].shbm :'（地方财政局）' }}</td>
                        <td width="160 ">{{listData[13].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[13].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[13].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[13].fj.length>0?'success':'danger'" size="mini">已上传{{listData[13].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[13],13,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">⭐实施方案批复</td>
                        <td width="80 ">{{listData[14].jyzq}}</td>
                        <td width="100 ">{{listData[14].jhwcsj?listData[14].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[14].sfcq ? listData[14].sfcq : ''">{{listData[14].pfsj}}</td>
                        <td :class="listData[14].shbm ? '' : 'blue'">{{listData[14].shbm ? listData[14].shbm :'（地方政府）' }}</td>
                        <td width="160 ">{{listData[14].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[14].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[14].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[14].fj.length>0?'success':'danger'" size="mini">已上传{{listData[14].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[14],14,'')">编辑</el-button>
                        </td>
                    </tr>


                    <tr>
                        <td rowspan="10">3</td>
                        <td rowspan="10" width="37 "  class="ver">初步设计阶段</td>
                        <td width="160 " colspan="2">招标采购</td>
                        <td width="80 ">{{listData[15].jyzq}}</td>
                        <td width="100 ">{{listData[15].jhwcsj?listData[15].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[15].sfcq ? listData[15].sfcq : ''">{{listData[15].pfsj}}</td>
                        <td :class="listData[15].shbm ? '' : 'xx'">{{listData[15].shbm ? listData[15].shbm :'' }}</td>
                        <td width="160 ">{{listData[15].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[15].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[15].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[15].fj.length>0?'success':'danger'" size="mini">已上传{{listData[15].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[15],15,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">初勘外业验收</td>
                        <td width="80 ">{{listData[16].jyzq}}</td>
                        <td width="100 ">{{listData[16].jhwcsj?listData[16].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[16].sfcq ? listData[16].sfcq : ''">{{listData[16].pfsj}}</td>
                        <td :class="listData[16].shbm ? '' : 'xx'">{{listData[16].shbm ? listData[16].shbm :'' }}</td>
                        <td width="160 ">{{listData[16].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[16].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[16].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[16].fj.length>0?'success':'danger'" size="mini">已上传{{listData[16].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[16],16,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">初步设计文件提交	</td>
                        <td width="80 ">{{listData[17].jyzq}}</td>
                        <td width="100 ">{{listData[17].jhwcsj?listData[17].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[17].sfcq ? listData[17].sfcq : ''">{{listData[17].pfsj}}</td>
                        <td :class="listData[17].shbm ? '' : 'xx'">{{listData[17].shbm ? listData[17].shbm :'' }}</td>
                        <td width="160 ">{{listData[17].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[17].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[17].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[17].fj.length>0?'success':'danger'" size="mini">已上传{{listData[17].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[17],17,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">初步设计评审</td>
                        <td width="80 ">{{listData[18].jyzq}}</td>
                        <td width="100 ">{{listData[18].jhwcsj?listData[18].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[18].sfcq ? listData[18].sfcq : ''">{{listData[18].pfsj}}</td>
                        <td :class="listData[18].shbm ? '' : 'xx'">{{listData[18].shbm ? listData[18].shbm :'' }}</td>
                        <td width="160 ">{{listData[18].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[18].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[18].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[18].fj.length>0?'success':'danger'" size="mini">已上传{{listData[18].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[18],18,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " rowspan="5" colspan="1">专项评估批复</td>
                        <td width="160 " colspan="1">专项评估报告编制</td>
                        <td width="80 ">{{listData[19].jyzq}}</td>
                        <td width="100 ">{{listData[19].jhwcsj?listData[19].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[19].sfcq ? listData[19].sfcq : ''">{{listData[19].pfsj}}</td>
                        <td :class="listData[19].shbm ? '' : 'xx'">{{listData[19].shbm ? listData[19].shbm :'' }}</td>
                        <td width="160 ">{{listData[19].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[19].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[19].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[19].fj.length>0?'success':'danger'" size="mini">已上传{{listData[19].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[19],19,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 ">防洪评价批复</td>
                        <td width="80 ">{{listData[20].jyzq}}</td>
                        <td width="100 ">{{listData[20].jhwcsj?listData[20].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[20].sfcq ? listData[20].sfcq : ''">{{listData[20].pfsj}}</td>
                        <td >{{listData[20].shbm ? listData[20].shbm :'松辽委/省水利厅/地方水务局' }}</td>
                        <td width="160 ">{{listData[20].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[20].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[20].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[20].fj.length>0?'success':'danger'" size="mini">已上传{{listData[20].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[20],20,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 ">文物调查批复</td>
                        <td width="80 ">{{listData[21].jyzq}}</td>
                        <td width="100 ">{{listData[21].jhwcsj?listData[21].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[21].sfcq ? listData[21].sfcq : ''">{{listData[21].pfsj}}</td>
                        <td >{{listData[21].shbm ? listData[21].shbm :'省文旅厅' }}</td>
                        <td width="160 ">{{listData[21].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[21].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[21].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[21].fj.length>0?'success':'danger'" size="mini">已上传{{listData[21].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[21],21,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 ">通航论证批复</td>
                        <td width="80 ">{{listData[22].jyzq}}</td>
                        <td width="100 ">{{listData[22].jhwcsj?listData[22].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[22].sfcq ? listData[22].sfcq : ''">{{listData[22].pfsj}}</td>
                        <td >{{listData[22].shbm ? listData[22].shbm :'省交通厅' }}</td>
                        <td width="160 ">{{listData[22].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[22].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[22].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[22].fj.length>0?'success':'danger'" size="mini">已上传{{listData[22].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[22],22,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 ">涉铁（公）路批复</td>
                        <td width="80 ">{{listData[23].jyzq}}</td>
                        <td width="100 ">{{listData[23].jhwcsj?listData[23].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[23].sfcq ? listData[23].sfcq : ''">{{listData[23].pfsj}}</td>
                        <td >{{listData[23].shbm ? listData[23].shbm :'哈铁局/省交通厅' }}</td>
                        <td width="160 ">{{listData[23].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[23].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[23].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[23].fj.length>0?'success':'danger'" size="mini">已上传{{listData[23].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[23],23,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">⭐初步设计批复</td>
                        <td width="80 ">{{listData[24].jyzq}}</td>
                        <td width="100 ">{{listData[24].jhwcsj?listData[24].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[24].sfcq ? listData[24].sfcq : ''">{{listData[24].pfsj}}</td>
                        <td >{{listData[24].shbm ? listData[24].shbm :'省交通厅' }}</td>
                        <td width="160 ">{{listData[24].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[24].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[24].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[24].fj.length>0?'success':'danger'" size="mini">已上传{{listData[24].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[24],24,'')">编辑</el-button>
                        </td>
                    </tr>

                    <tr>
                        <td rowspan="4">4</td>
                        <td rowspan="4" width="37 "  class="ver">施工图设计阶段</td>
                        <td width="160 " colspan="2">招标采购</td>
                        <td width="80 ">{{listData[25].jyzq}}</td>
                        <td width="100 ">{{listData[25].jhwcsj?listData[25].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[25].sfcq ? listData[25].sfcq : ''">{{listData[25].pfsj}}</td>
                        <td :class="listData[25].shbm ? '' : 'xx'">{{listData[25].shbm ? listData[25].shbm :'' }}</td>
                        <td width="160 ">{{listData[25].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[25].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[25].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[25].fj.length>0?'success':'danger'" size="mini">已上传{{listData[25].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[25],25,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">施工图设计文件提交	</td>
                        <td width="80 ">{{listData[26].jyzq}}</td>
                        <td width="100 ">{{listData[26].jhwcsj?listData[26].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[26].sfcq ? listData[26].sfcq : ''">{{listData[26].pfsj}}</td>
                        <td :class="listData[26].shbm ? '' : 'xx'">{{listData[26].shbm ? listData[26].shbm :'' }}</td>
                        <td width="160 ">{{listData[26].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[26].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[26].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[26].fj.length>0?'success':'danger'" size="mini">已上传{{listData[26].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[26],26,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">施工图设计评审		</td>
                        <td width="80 ">{{listData[27].jyzq}}</td>
                        <td width="100 ">{{listData[27].jhwcsj?listData[27].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[27].sfcq ? listData[27].sfcq : ''">{{listData[27].pfsj}}</td>
                        <td :class="listData[27].shbm ? '' : 'xx'">{{listData[27].shbm ? listData[27].shbm :'' }}</td>
                        <td width="160 ">{{listData[27].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[27].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[27].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[27].fj.length>0?'success':'danger'" size="mini">已上传{{listData[27].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[27],27,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td width="160 " colspan="2">⭐施工图设计批复	</td>
                        <td width="80 ">{{listData[28].jyzq}}</td>
                        <td width="100 ">{{listData[28].jhwcsj?listData[28].jhwcsj.slice(0,10) : ''}}</td>
                        <td width="160 " :class="listData[28].sfcq ? listData[28].sfcq : ''">{{listData[28].pfsj}}</td>
                        <td :class="listData[28].shbm ? '' : 'xx'">{{listData[28].shbm ? listData[28].shbm :'' }}</td>
                        <td width="160 ">{{listData[28].gzjzms}}</td>
                        <td>
                            <el-popover trigger="hover" placement="top" v-if="listData[28].fj.length > 0">
                                <ul class="fj-list">
                                    <li v-for="(item,i) in listData[28].fj" :key="i">
                                        <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                        <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                    </li>
                                </ul>

                                <div slot="reference" class="name-wrapper">
                                    <el-tag :type="listData[28].fj.length>0?'success':'danger'" size="mini">已上传{{listData[28].fj.length}}个</el-tag>
                                </div>
                            </el-popover>
                        </td>
                        <td>
                            <el-button size="mini" type="success"  @click="openEdit(listData[28],28,'')">编辑</el-button>
                        </td>
                    </tr>


                    <tr>
                        <td rowspan="1">5</td>
                        <td width="160 " colspan="3">施工招标时间</td>
                        <td width="160 "></td>
                        <td colspan="7" :class="listData[29].sfcq ? listData[29].sfcq : ''" >{{listData[29].jhwcsj}}</td>
                        <td width="160">
                            <el-button size="mini" type="success"  @click="openEdit(listData[29],29,'')">编辑</el-button>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="1">6</td>
                        <td width="160 " colspan="3">基本情况</td>
                        <td width="160 ">
                        </td>
                        <td colspan="8" style="text-align: left"> {{jbqk}}</td>
                    </tr>
                </table>


<!--                <el-collapse   v-model="activeName" accordion>-->
<!--                    <el-collapse-item name="工可">-->
<!--                        <template slot="title">-->
<!--                            <div class="coll-title">工可</div>-->
<!--                            &lt;!&ndash;                    <div style="margin-left: 24px" v-if="gkData" :style="{color:gkData[4].blzt == 0 ? '#909399' :gkData[4].blzt == 1 ? '#E6A23C' : '#67C23A'}">{{gkData[4].blzt == 0 ? '未开展' :gkData[4].blzt == 1 ? '办理中' : '已批复'}}</div>&ndash;&gt;-->
<!--                        </template>-->
<!--                        <el-table ref="table" :data="gkData" size="small "  border :default-sort="{ prop: 'SSDS', order: 'ascending' }"  style="width: 99%">-->
<!--                            <el-table-column  label="操作" width="80">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-button  size="mini" type="success"  @click="openEdit(scope.row,scope.$index,'GK')">编辑</el-button>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column  type="index" align="center" label="序号"  width="50"></el-table-column>-->
<!--                            <el-table-column  prop="jdmc" label="节点名称"  ></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="jhwcsj" width="110" label="计划完成时间">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{scope.row.jhwcsj ? scope.row.jhwcsj.slice(0,10) : ''}}-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfdw" width="110" label="批复单位"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfsj"  width="120" label="批复时间" ></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfwjbt"  width="120"  label="批复文件标题"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfwh" label="批复文号"></el-table-column>-->
<!--                            <el-table-column  prop="blzt" label="办理状态">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{scope.row.blzt == 0 ? '未开展' : scope.row.blzt == 1 ? '办理中' : '已办结'}}-->

<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="gzjzms"  width="120"  label="工作进展描述"></el-table-column>-->
<!--                            <el-table-column  prop="name" label="附件" align="center">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-popover trigger="hover" placement="top" v-if="scope.row.fj.length > 0">-->
<!--                                        <ul class="fj-list">-->
<!--                                            <li v-for="(item,i) in scope.row.fj" :key="i">-->
<!--                                                <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>-->
<!--                                                &lt;!&ndash;                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>&ndash;&gt;-->
<!--                                                &lt;!&ndash;                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>&ndash;&gt;-->
<!--                                            </li>-->
<!--                                        </ul>-->

<!--                                        <div slot="reference" class="name-wrapper">-->
<!--                                            <el-tag :type="scope.row.fj.length>0?'success':'danger'" size="mini">已上传{{scope.row.fj.length}}个</el-tag>-->
<!--                                        </div>-->
<!--                                    </el-popover>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->

<!--                    </el-collapse-item>-->
<!--                    <el-collapse-item name="初设">-->
<!--                        <template slot="title">-->
<!--                            <div class="coll-title">初设</div>-->
<!--                            &lt;!&ndash;                    <div style="margin-left: 24px" v-if="csData" :style="{color:csData[5].blzt == 0 ? '#909399' :csData[5].blzt == 1 ? '#E6A23C' : '#67C23A'}">{{csData[5].blzt == 0 ? '未开展' :csData[5].blzt == 1 ? '办理中' : '已批复'}}</div>&ndash;&gt;-->
<!--                        </template>-->
<!--                        <el-table ref="table" :data="csData" size="small "  border :default-sort="{ prop: 'SSDS', order: 'ascending' }"  style="width: 99%">-->
<!--                            <el-table-column  label="操作" width="80">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-button size="mini" type="success"  @click="openEdit(scope.row,scope.$index,'CBSJ')">编辑</el-button>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column  type="index" label="序号" width="50"></el-table-column>-->
<!--                            <el-table-column  prop="jdmc" label="节点名称"  width="150"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="jhwcsj" width="110" label="计划完成时间">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{scope.row.jhwcsj ? scope.row.jhwcsj.slice(0,10) : ''}}-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfdw" width="110" label="批复单位"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfsj"  width="120" label="批复时间" ></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfwjbt"  width="120"  label="批复文件标题"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfwh" label="批复文号"></el-table-column>-->
<!--                            <el-table-column  prop="blzt" label="办理状态">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{scope.row.blzt == 0 ? '未开展' : scope.row.blzt == 1 ? '办理中' : '已办结'}}-->

<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="gzjzms"  width="120"  label="工作进展描述"></el-table-column>-->
<!--                            <el-table-column  prop="name" label="附件" align="center">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-popover trigger="hover" placement="top" v-if="scope.row.fj.length > 0">-->
<!--                                        <ul class="fj-list">-->
<!--                                            <li v-for="(item,i) in scope.row.fj" :key="i">-->
<!--                                                <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>-->
<!--                                                &lt;!&ndash;                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>&ndash;&gt;-->
<!--                                                &lt;!&ndash;                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>&ndash;&gt;-->
<!--                                            </li>-->
<!--                                        </ul>-->

<!--                                        <div slot="reference" class="name-wrapper">-->
<!--                                            <el-tag :type="scope.row.fj.length>0?'success':'danger'" size="mini">已上传{{scope.row.fj.length}}个</el-tag>-->
<!--                                        </div>-->
<!--                                    </el-popover>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->

<!--                    </el-collapse-item>-->
<!--                    <el-collapse-item  name="施工图">-->
<!--                        <template slot="title">-->
<!--                            <div class="coll-title">施工图</div>-->
<!--                            &lt;!&ndash;                    <div style="margin-left: 24px" v-if="sgtData" :style="{color:sgtData[1].blzt == 0 ? '#909399' :sgtData[1].blzt == 1 ? '#E6A23C' : '#67C23A'}">{{sgtData[1].blzt == 0 ? '未开展' :sgtData[1].blzt == 1 ? '办理中' : '已批复'}}</div>&ndash;&gt;-->

<!--                        </template>-->
<!--                        <el-table ref="table" :data="sgtData" size="small "  border :default-sort="{ prop: 'SSDS', order: 'ascending' }"  style="width: 99%">-->
<!--                            <el-table-column  label="操作" width="80">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-button size="mini" type="success"  @click="openEdit(scope.row,scope.$index,'SGTZB')">编辑</el-button>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column  type="index" label="序号" width="50"></el-table-column>-->
<!--                            <el-table-column  prop="jdmc" label="节点名称"  width="150"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="jhwcsj" width="110" label="计划完成时间">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{scope.row.jhwcsj ? scope.row.jhwcsj.slice(0,10) : ''}}-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfdw" width="110" label="批复单位"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfsj"  width="120" label="批复时间" ></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfwjbt"  width="120"  label="批复文件标题"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfwh" label="批复文号"></el-table-column>-->
<!--                            <el-table-column  prop="blzt" label="办理状态">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{scope.row.blzt == 0 ? '未开展' : scope.row.blzt == 1 ? '办理中' : '已办结'}}-->

<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="gzjzms"  width="120"  label="工作进展描述"></el-table-column>-->
<!--                            <el-table-column  prop="name" label="附件" align="center">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-popover trigger="hover" placement="top" v-if="scope.row.fj.length > 0">-->
<!--                                        <ul class="fj-list">-->
<!--                                            <li v-for="(item,i) in scope.row.fj" :key="i">-->
<!--                                                <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>-->
<!--                                                &lt;!&ndash;                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>&ndash;&gt;-->
<!--                                                &lt;!&ndash;                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>&ndash;&gt;-->
<!--                                            </li>-->
<!--                                        </ul>-->

<!--                                        <div slot="reference" class="name-wrapper">-->
<!--                                            <el-tag :type="scope.row.fj.length>0?'success':'danger'" size="mini">已上传{{scope.row.fj.length}}个</el-tag>-->
<!--                                        </div>-->
<!--                                    </el-popover>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->

<!--                    </el-collapse-item>-->
<!--                    <el-collapse-item  name="施工前">-->
<!--                        <template slot="title">-->
<!--                            <div class="coll-title">施工前</div>-->
<!--                        </template>-->
<!--                        <el-table ref="table" :data="sgqData" size="small "  border :default-sort="{ prop: 'SSDS', order: 'ascending' }"  style="width: 99%">-->
<!--                            <el-table-column  label="操作" width="80">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-button size="mini" type="success"  @click="openEdit(scope.row,scope.$index,'SGQZB')">编辑</el-button>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column  type="index" label="序号" width="50"></el-table-column>-->
<!--                            <el-table-column  prop="jdmc" label="节点名称"  width="150"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="jhwcsj" width="110" label="计划完成时间">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{scope.row.jhwcsj ? scope.row.jhwcsj.slice(0,10) : ''}}-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfdw" width="110" label="批复单位"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfsj"  width="120" label="批复时间" ></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfwjbt"  width="120"  label="批复文件标题"></el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="pfwh" label="批复文号"></el-table-column>-->
<!--                            <el-table-column  prop="blzt" label="办理状态">-->
<!--                                <template slot-scope="scope">-->
<!--                                    {{scope.row.blzt == 0 ? '未开展' : scope.row.blzt == 1 ? '办理中' : '已办结'}}-->

<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column show-overflow-tooltip prop="gzjzms"  width="120"  label="工作进展描述"></el-table-column>-->
<!--                            <el-table-column  prop="name" label="附件" align="center">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-popover trigger="hover" placement="top" v-if="scope.row.fj.length > 0">-->
<!--                                        <ul class="fj-list">-->
<!--                                            <li v-for="(item,i) in scope.row.fj" :key="i">-->
<!--                                                <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>-->
<!--                                                &lt;!&ndash;                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>&ndash;&gt;-->
<!--                                                &lt;!&ndash;                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>&ndash;&gt;-->
<!--                                            </li>-->
<!--                                        </ul>-->

<!--                                        <div slot="reference" class="name-wrapper">-->
<!--                                            <el-tag :type="scope.row.fj.length>0?'success':'danger'" size="mini">已上传{{scope.row.fj.length}}个</el-tag>-->
<!--                                        </div>-->
<!--                                    </el-popover>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->

<!--                    </el-collapse-item>-->
<!--                </el-collapse>-->
            </el-form>
            <!--&lt;!&ndash;        <i class="el-icon-circle-plus-outline" @click="addmembers"></i>&ndash;&gt;-->
            <!--        <el-form :disabled="isLock" :inline="true" :model="formInline" class="demo-form-inline flow" style="margin-top: 0px;display:flex">-->
            <!--            <el-table ref="table" :data="listData" size="small "  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" :height="tableHeight" style="width: 99%">-->
            <!--                <el-table-column fixed type="index" label="序号" width="50"></el-table-column>-->
            <!--                <el-table-column fixed prop="name" label="节点名称"  width="200">-->
            <!--                    <template slot-scope="scope">-->
            <!--                        <el-form-item>-->
            <!--                        <el-input :disabled="true" v-model="scope.row.jdmc" autocomplete="off" size="mini" placeholder="请输入节点名称"></el-input>-->
            <!--                        </el-form-item>-->
            <!--                    </template>-->
            <!--                </el-table-column>-->
            <!--                <el-table-column  width="200" prop="name" label="计划完成时间">-->
            <!--                    <template slot-scope="scope">-->
            <!--                        <el-form-item>-->
            <!--                            <el-date-picker  style="width: 178px"-->

            <!--                                             format="yyyy-MM-dd"-->
            <!--                                             value-format="yyyy-MM-dd"-->
            <!--                                             v-model="scope.row.jhwcsj" type="date" placeholder="选择日期">-->
            <!--                            </el-date-picker>-->
            <!--                        </el-form-item>-->
            <!--                    </template>-->
            <!--                </el-table-column>-->
            <!--                <el-table-column width="200" prop="name" label="批复单位">-->
            <!--                    <template slot-scope="scope">-->
            <!--                        <el-form-item>-->
            <!--                        <el-input v-model="scope.row.pfdw" autocomplete="off" size="mini" placeholder="请输入批复单位"></el-input>-->
            <!--                        </el-form-item>-->
            <!--                    </template>-->
            <!--                </el-table-column>-->
            <!--                <el-table-column width="200" prop="name" label="批复时间" >-->
            <!--                    <template slot-scope="scope">-->
            <!--                        <el-form-item>-->
            <!--                        <el-date-picker  style="width: 178px"-->

            <!--                                         format="yyyy-MM-dd"-->
            <!--                                         value-format="yyyy-MM-dd"-->
            <!--                                         v-model="scope.row.pfsj" type="date" placeholder="选择日期">-->
            <!--                        </el-date-picker>-->
            <!--                        </el-form-item>-->
            <!--                    </template>-->
            <!--                </el-table-column>-->
            <!--                <el-table-column width="200" prop="name" label="批复文件标题">-->
            <!--                    <template slot-scope="scope">-->
            <!--                        <el-form-item>-->
            <!--                        <el-input v-model="scope.row.pfwjbt" autocomplete="off" size="mini" placeholder="请输入批复文件标题"></el-input>-->
            <!--                        </el-form-item>-->
            <!--                    </template>-->
            <!--                </el-table-column>-->
            <!--            <el-table-column width="200" prop="name" label="批复文号">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <el-form-item>-->
            <!--                    <el-input v-model="scope.row.pfwh" autocomplete="off" size="mini" placeholder="请输入批复文号"></el-input>-->
            <!--                    </el-form-item>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column width="200" prop="name" label="办理状态">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <el-form-item>-->
            <!--                        <el-select style="height: 32px" @change="changeStatus" placeholder="请选择办理状态" v-model="scope.row.blzt">-->
            <!--                            <el-option label="未开展" value="未开展"></el-option>-->
            <!--                            <el-option label="办理中"  value="办理中"></el-option>-->
            <!--                            <el-option label="已办结"  value="已办结"></el-option>-->
            <!--                        </el-select>-->
            <!--                    </el-form-item>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column width="200" prop="name" label="工作进展描述">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <el-form-item>-->
            <!--                        <el-input autosize style="margin-top: -5px;width: 180px" placeholder="请输入描述内容" type="textarea" v-model="scope.row.gzjzms"></el-input>-->
            <!--                    </el-form-item>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <!--                <el-table-column width="200" prop="name" label="附件" align="center">-->
            <!--                    <template slot-scope="scope">-->
            <!--                        <el-popover trigger="hover" placement="top" v-if="scope.row.fj.length > 0">-->
            <!--                            <ul class="fj-list">-->
            <!--                                <li v-for="(item,i) in scope.row.fj" :key="i">-->
            <!--                                    <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>-->
            <!--                                    <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
            <!--&lt;!&ndash;                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>&ndash;&gt;-->
            <!--                                </li>-->
            <!--                            </ul>-->

            <!--                            <div slot="reference" class="name-wrapper">-->
            <!--                                <el-tag :type="scope.row.fj.length>0?'success':'danger'" size="mini">已上传{{scope.row.fj.length}}个</el-tag>-->
            <!--                            </div>-->
            <!--                        </el-popover>-->
            <!--                    </template>-->
            <!--                </el-table-column>-->
            <!--                <el-table-column  label="操作" width="240">-->
            <!--                    <template slot-scope="scope">-->
            <!--                        <div style="display: inline;margin-right: 10px">-->
            <!--                            <label :for="'fileInput_'+scope.$index">-->
            <!--                                <i :style="{cursor: isLock ? 'not-allowed' :''}" aria-hidden="true" class="upload-demo cursor el-button  el-button&#45;&#45;primary el-button&#45;&#45;small">上传附件</i>-->
            <!--                            </label>-->
            <!--                            <input :disabled="isLock"  type="file" v-show="false" accept="*" name="file" ref="file" multiple="multiple" :id="'fileInput_'+scope.$index" @change="onSubmit($event,scope.$index)" />-->
            <!--                        </div>-->
            <!--                        <el-button size="mini" type="success"  @click="save(scope.row)">保存</el-button>-->
            <!--                        <el-button size="mini" type="danger" v-if="!scope.row.ID"  @click="delmembers(scope.$index,scope.row)">删除</el-button>-->
            <!--                    </template>-->
            <!--                </el-table-column>-->

            <!--            </el-table>-->
            <!--        </el-form>-->
        </el-col>

        <el-drawer :append-to-body="true" :visible.sync="showAddModel" size="1183px" :title="title"  class="editModal">
            <el-main>

                <el-form   :model="detail" ref="detailForm"  :inline="false" class="form-inline" v-loading="uploadLoading">
                    <div class="col" style="min-height: 500px;">
                        <div class="col-1">
                            <el-form-item label="项目阶段" >
                                <el-input :disabled="true" v-model="detail.jdmc" autocomplete="off" size="mini" placeholder="请输入项目阶段"></el-input>
                            </el-form-item>
                            <el-form-item  label="建议周期" >
                                <el-input  placeholder="请输入建议周期" type="number" v-model="detail.jyzq"></el-input>
                            </el-form-item>
                            <el-form-item prop="jhwcsj"  label="计划完成时间" :rules="[{  required: true,validator: (rule, value, callback)=>{vaildTime(rule, value, callback)},trigger: 'blur' }]">
                                <el-date-picker
                                        :disabled="detail.id != '00000000-0000-0000-0000-000000000000' && detail.jhwcsj != null ? true : false "
                                        style="width: 330px"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        v-model="detail.jhwcsj" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item  label="督导完成时限" v-if="isAdmin && detail.sfcq == 'bgRed'">
                                <el-date-picker
                                        style="height: 32px"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        v-model="detail.ddwcsx" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="办理状态" v-if="detail.jdmc != '施工招标时间'">
                                <el-select style="width: 330px" @change="changeStatus" placeholder="请选择办理状态" v-model="detail.blzt">
                                    <el-option label="未开展" :value="0"></el-option>
                                    <el-option label="办理中"  :value="1"></el-option>
                                    <el-option label="已办结"  :value="2"></el-option>
                                    <el-option label="不涉及"  :value="3"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="备注"  v-if="detail.jdmc != '施工招标时间'">
                                <el-input  style="margin-top: -5px;width: 180px" placeholder="请输入描述内容" type="textarea" v-model="detail.gzjzms"></el-input>
                            </el-form-item>
                            <el-form-item prop="wwcyy" label="未完成原因" v-if="detail.sfcq === 'bgRed'" :rules="[{ required: true, message:'请输入未完成原因',trigger: 'blur' }]">
                                <el-input  style="margin-top: -5px;width: 180px" placeholder="请输入未完成原因" type="textarea" v-model="detail.wwcyy"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-1" >
                            <el-form-item label="审批部门"  v-if="detail.jdmc != '施工招标时间'">
                                <el-input v-model="detail.shbm" autocomplete="off" size="mini" placeholder="请输入审批部门"></el-input>
                            </el-form-item>
<!--                            <el-form-item label="牵头部门及负责人" >-->
<!--                                <el-input v-model="detail.qtbmjfzr" autocomplete="off" size="mini" placeholder="请输入批复单位"></el-input>-->
<!--                            </el-form-item>-->
<!--                            <el-form-item label="责任部门及负责人" >-->
<!--                                <el-input v-model="detail.zrbmjzrr" autocomplete="off" size="mini" placeholder="请输入批复单位"></el-input>-->
<!--                            </el-form-item>-->
<!--                            <div style="color: red">注:（责任部门为省公路中心（省建设中心）、地方政府；责任人为部门一把手或县长）</div>-->
                            <el-form-item label="批复单位"  v-if="detail.jdmc != '施工招标时间'">
                                <el-input v-model="detail.pfdw" autocomplete="off" size="mini" placeholder="请输入批复单位"></el-input>
                            </el-form-item>
                            <el-form-item  label="实际完成时间">
                                <el-date-picker
                                        style="height: 32px"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        v-model="detail.pfsj" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item  label="批复文件标题"  v-if="detail.jdmc != '施工招标时间'">
                                <el-input v-model="detail.pfwjbt" autocomplete="off" size="mini" placeholder="请输入批复文件标题"></el-input>
                            </el-form-item>
                            <el-form-item label="批复文号"  v-if="detail.jdmc != '施工招标时间'">
                                <el-input v-model="detail.pfwh" autocomplete="off" size="mini" placeholder="请输入批复文号"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-1" >
                            <el-form-item label="附件">
                                <label  class="fright" style="float:left;">
                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                    <input type="file" v-show="false"  name="file" ref="file" id="fileInput"  multiple="multiple" @change="onSubmit($event,'jsxmydys')" />
                                </label>
                            </el-form-item>
                            <div :span="3" class="file" v-for="(item,index) in detail.fj" :key="index">
                                <a target="view_window" :href="baseUrl+item.url+item.name">
                                    <i class="el-icon-paperclip" style="color: #409EFF"></i><span  style="margin-right: 6px;color: #409eff">{{item.name}} </span>
                                </a>
                                <i style="color: #409EFF" class="el-icon-delete" @click.stop="deleted(index,'jsxmydys')"></i>
                            </div>
                        </div>
                    </div>
                    <el-row>

                    </el-row>
                    <!--                <div style="color: red">* 以下三种文件不要上传：（1）密级为【秘密】及以上、（2）文件中含省领导签字、（3）军用图、边防图。</div>-->
                </el-form>
                <div class="footer">
                    <el-button type="primary" @click="save">保存</el-button>
                    <el-button @click="cancel">取消</el-button>
                </div>
            </el-main>

        </el-drawer>

    </div>
</template>

<script>
    export default {
        name: "FlowTable",
        components: {},

        props:{
            qqjzid: { type: String },
            GKWCD:  { type: String },
            CBSJWCD:  { type: String },
            SGTSJWCD:  { type: String },
            SGQZBWCD:  { type: String },
            addStatus:  { type: Boolean },
            isGg: { type: Boolean },
            isLock: { type: Boolean },
            jbqk:{type: String}
        },
        data(){
            return {
                isAdmin: false,
                activeName: '工可',
                tagList: [],
                formInline: {},
                listData: [],
                gkData: [],
                csData: [],
                sgtData: [],
                sgqData: [],
                confirmChange: false,
                baseUrl: "http://82.156.50.94:8002/",
                status: '1',
                showAddModel: false,
                uploadLoading: false,
                detail: {
                    fj: []
                },
                title: '',
                gg: true,
                percent: '80%',
                scale: 100,
                Collapse: '1',
                jdmc: '',
                lctfl: '',
                qqId: '',
                result: {
                    GK: {},
                    CBSJ: {},
                    SGTZB: {},
                    SGQZB: {}
                },
                gkwcd: '',
                cbsjwcd: '',
                sgtsjwcd: '',
                shqzbwcd: '',
                dealStatus: '',
                changeDateSwich: true,
                orignDate: '',
                changeTimeReason: '',
                newAdd: true,
                tableHeight: document.body.offsetHeight - 200,
                newOne: true,
                flag: null,
                timeout: null,
            }
        },
        mounted() {
            var that = this
            this.listData = []
            console.log(111)
            // let height = this.$refs.init.offsetHeight;
            this.flag = false
            this.$nextTick(function(){
                this.setColSpan();
            })

            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' || res.data.userTrueName== '黑龙江省公路事业发展中心' ||  res.data.isMain  == 1 ? true : false
            })
        },
        watch:{
            GKWCD(val){
                this.gkwcd = val
            },
            CBSJWCD(val){
                this.cbsjwcd = val
            },
            SGTSJWCD(val){
                this.sgtsjwcd = val
            },
            SGQZBWCD(val){
                this.shqzbwcd = val
            },
            isGg(val){
                this.gg  =val
            },

            addStatus(val){
                this.newOne = val
                if (val){
                    this.listData = []
                }else{
                    console.log(val)

                }
            },
            qqjzid:{
                handler(val){
                    this.qqId = val
                    this.Collapse = '1'
                    if(val){
                        this.getData(this.qqId)

                        // this.getTagList()
                    }
                },
                immediate: true
            },
        },
        methods:{
            vaildTime (rule,value,callback){
                console.log(value)
                if (this.detail.blzt != 3 && value == null) {
                    callback(new Error('请选择计划完成时间'))
                } else {
                    callback()
                }
            },
            setColSpan:function() {
                //this.$refs.el-table__header
                // console.log(document.getElementsByClassName("el-table__header"))
                // 获取表头的所有单元格
                // let n = document.getElementsByClassName("el-table__header")[0].rows[0].cells
                // console.log(n)
                // // 将第二列表头单元格的colSpan设为2
                // n[2].colSpan = 3
                // 将第三列表头单元格的display设为none
                // n[2].style.display = 'none'
            },
            objectSpanMethod ({ row, column, rowIndex, columnIndex }) { // 框架自带合并方法
                if (rowIndex == 2) {
                    return {
                        colspan:1,
                        rowspan: 4,
                    };
                }
            //     // 下面用的三元表达式的嵌套
            //     const _row = columnIndex === 1 ? this.firstClsfIndexArr[rowIndex] : columnIndex === 2 ? this.secondClsfIndexArr[rowIndex] : this.bizNameIndexArr[rowIndex]
            //     const _col = _row > 0 ? 1 : 0
            //     return {
            //         rowspan: _row,
            //         colspan: _col,
            //     }
            },
            getTagList(){
                this.flag = true
                clearTimeout(this.timeout)
                this.http.post('/api/Plan_high_national_early_node_type/GetPageData',{rows:50,Sort: "XH",
                    Order: "asc",}).then(res=>{
                    this.tagList = res.rows
                    this.tagList.map((item,index)=>{
                        item.disabled = false
                    })
                })
            },
            chooseT(val){
            },
            addmembers(val) {
                console.log(val)
                this.tagList.map((item,index)=>{
                    if (item.MC == val){
                        item.disabled = true
                    }
                })
                var member = this.listData;
                var length = member.length;
                this.listData.push({jdmc:val, fj:[], jhwcsj:'', pfdw:'', pfsj:'', pfwjbt:'', pfwh:'', blzt:'', gzjzms:''})
            },
            delmembers(index,row) {
                var that = this;
                this.$confirm('确认删除吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //点击确定的操作(调用接口)
                    var hasmembers = that.listData;
                    that.listData.splice(index, 1);
                    this.tagList.map((item,index)=>{
                        if (item.MC == row.jdmc){
                            item.disabled = false
                        }
                    })
                    // this.$message({ message: '删除成功', duration: 2000, type: 'success' });
                }).catch(() => {
                    //点取消的提示
                    return;
                });
            },

            changeTime(val){
                if (this.detail.ID){
                    this.changeTimeReason = 'little'
                }
                if (this.orignDate){
                    this.changeTimeReason  = this.tab(this.orignDate.slice(0,10),val)
                }
            },
            changeStatus(val){
                this.dealStatus = val
            },
            // 比较大小
            tab(date1,date2){
                var oDate1 = new Date(date1);
                var oDate2 = new Date(date2);
                if(oDate1.getTime() > oDate2.getTime()){
                    return 'little'
                } else if(oDate1.getTime() == oDate2.getTime()) {
                    return 'same'
                }else{
                    return 'big'
                }
            },
            getNowFormatDate() {
                var date = new Date();
                var seperator1 = "-";
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                var currentdate = year + seperator1 + month  + seperator1 + strDate;
                return currentdate;
            },
            //计算差值

            DateDiff(date1,date2){ //sDate1和sDate2是2006-12-18格式
                let _this = this;
                let flag = [1, 3, 5, 7, 8, 10, 12, 4, 6, 9, 11, 2];
                var cah =  this.tab(date1,date2)
                let start = new Date(date1);
                let end = new Date(date2);
                let year =end.getFullYear() - start.getFullYear();
                var day, month;
                if (cah == 'big'){
                    month =end.getMonth() - start.getMonth();
                    day =end.getDate() - start.getDate();
                    if (month < 0) {
                        year--;
                        month = end.getMonth() + (12 - start.getMonth());
                    }
                    if (day < 0) {
                        month--;
                        let index = flag.findIndex((temp) => {
                            return temp === start.getMonth() + 1
                        });
                        let monthLength;
                        if (index <= 6) {
                            monthLength = 31;
                        } else if (index > 6 && index <= 10) {
                            monthLength = 30;
                        } else {
                            monthLength = 28;
                        }
                        day = end.getDate() + (monthLength - start.getDate());
                    }
                }else{
                    month =start.getMonth() - end.getMonth();
                    day = start.getDate() -end.getDate();
                    if (day < 0) {
                        if (month > 0){
                            month --
                        }
                        let index = flag.findIndex((temp) => {
                            return temp === start.getMonth() + 1
                        });
                        let monthLength;
                        if (index <= 6) {
                            monthLength = 31;
                        } else if (index > 6 && index <= 10) {
                            monthLength = 30;
                        } else {
                            monthLength = 28;
                        }
                        day =start.getDate()+ (monthLength -  end.getDate());
                    }
                }
                var obj = {
                    year: (year == 0?'':  Math.abs(year)+'年') + (month == 0?'':  Math.abs(month)+'月')+ (day == 0?'':  Math.abs(day)+'天'),
                }
                //小于半个月
                if(-15<day<0 && year == 0 && month == 0 ){
                    obj.color='#E6FF00'
                }

                //一个月到半个月之间
                if(year == 0 && month == 0 && (-15>= day >= -30)){
                    obj.color='#00FF77'
                }
                //延期
                if (cah == 'big'){
                    if((day >= 0 && month >= 0 && year >= 0) || (month > 0 && year >= 0) || year > 0){
                        obj.color='#FB0A0A'
                    }
                }


                return obj
            },

            deleted(index,name){
                // console.log(index,name,this.detail)
                this.detail.fj.splice(index,1)
            },
            getData(id){
                var that = this
                this.gkData = []
                this.csData = []
                this.sgtData = []
                this.sgqData = []
                // this.http.post('/api/Plan_high_national_early_node/GetPageData',{rows:50,Wheres:JSON.stringify([{Name:'QQJZID',Value:id,DisplayType: "Equal"}])}).then(res=>{
                this.http.get('/api/Plan_high_national_early_node/GetAllNode?id='+id,).then(res=>{
                    this.listData = res.data
                    console.log(this.listData,'this.listDatathis.listData')
                    // this.tagList.map((item,index)=>{
                    //
                    //     item.disabled = false
                    //     res.rows.map((subTtem,subIndex)=>{
                    //         if (item.MC == subTtem.jdmc){
                    //             item.disabled = true
                    //         }
                    //     })
                    //
                    // })
                    this.listData.map((item,index)=>{
                        if(item.jhwcsj){
                            item.jhwcsj = item.jhwcsj.slice(0,10)
                        }
                        var a = []
                        if(item.blzt!= 3){
                            if(item.pfsj){
                                if(Date.parse(item.pfsj) > Date.parse( item.jhwcsj)){
                                    item.sfcq = 'red' //0;超期完成
                                }else{
                                    item.sfcq = 'green' //2;正常时间完成
                                }
                            }else{
                                if(Date.parse(item.jhwcsj) < Date.parse(new Date())){
                                    item.sfcq = 'bgRed' //1;超期未完成
                                }
                            }
                        }else{
                            item.sfcq = 'greenx' //3;不涉及
                        }
                        if(item.fj != '' && item.fj != null){
                            item.fj = item.fj.split(';')
                            item.fj.map((subItem)=>{
                                subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                a.push(subItem)
                            })
                            item.fj = a
                        }else {
                            item.fj = []
                        }
                        // switch (item.lctfl) {
                        //     case 0:
                        //         that.gkData.push(item)
                        //         break;
                        //     case 1:
                        //         that.csData.push(item)
                        //         break;
                        //     case 2:
                        //         that.sgtData.push(item)
                        //         break;
                        //     case 3:
                        //         that.sgqData.push(item)
                        //         break;
                        // }

                    })
                    this.detail = {fj:[]}
                })
            },
            onSubmit(e, index) {
                console.log(e,index,this.detail)
                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early_node/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail.fj.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            save(row){
                this.$refs.detailForm.validate((valid) => {
                    this.saveLoading = true
                    if (valid) {
                        if(this.detail.blzt === ''){
                            this.$message.warning('请选择办理状态')
                            return false
                        }
                        var params = JSON.parse(JSON.stringify(this.detail))
                        var pfwjString = []
                        params.fj.map((item)=>{
                            pfwjString.push(item.url+item.name)
                        })
                        params.fj = pfwjString.join(';')
                        params.QQJZID = this.qqId
                        if(this.detail.id == '00000000-0000-0000-0000-000000000000'){
                            this.http.post('/api/Plan_high_national_early_node/AddPlanHighNationalEarlyNode',params).then(res=>{
                                if(res.status){
                                    this.dealStatus = ''
                                    this.$message.success('添加成功')
                                    this.showAddModel = false
                                    this.detail ={fj:[]}
                                    this.getData(this.qqId)
                                    // this.$parent.search();
                                }else{
                                    this.$message.error(res.message)
                                }
                            })

                        }else{
                            this.http.post('/api/Plan_high_national_early_node/UpdatePlanHighNationalEarlyNode',params).then(res=>{
                                if(res.status){
                                    this.$message.success('保存成功')
                                    this.showAddModel = false
                                    this.dealStatus = ''
                                    this.detail ={fj:[]}
                                    this.getData(this.qqId)
                                    console.log(this.$parent)
                                    // this.$parent.search();
                                }else{
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    }
                })

            },
            checkNull(rule, value, callback){
                if (!value) {
                    callback(new Error('请选择时间'));
                }else{
                    callback();
                }
            },
            cancel(){
                this.showAddModel = false
            },
            openEdit(row,index,flowName){
                console.log(index,flowName)
                this.flowName = flowName
                // this.flowIndex = index
                // var that = this
                // this.changeTimeReason = ''
                this.detail = JSON.parse(JSON.stringify(row))
                this.showAddModel =true
                this.title = row.jdmc
                // this.jdmc = jdmc
                // this.lctfl =  lctfl
                // if ( this.result[lctfl][jdmc] ){
                //     this.detail =  JSON.parse(JSON.stringify(this.result[lctfl][jdmc]))
                //     this.dealStatus =  this.detail.blzt
                //     this.orignDate = this.detail.jhwcsj
                //     this.newAdd = true
                //     // this.changeDateSwich = this.detail.jhwcsj ? true
                // }else{
                //     this.newAdd = false
                //
                // }
            },
            changeCollapse(name){
                console.log(name)
            },
        }
    }
</script>

<style lang="less" scoped>
    .roadFront2{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        margin-bottom: 15px;
        height: 32px;
        padding:0px !important;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        min-height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .roadFront2 {
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin:0;
        }
        /deep/.el-collapse-item__content {
            padding:5px 0px 20px 0px  !important;
        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #FAFAFA;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /deep/ .el-input--suffix{
        width: 330px;
    }
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    gTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        width: 350px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 15px;
        border:1px solid #DCDFE6;
        border-radius: 2px;
        .dwmc{
            text-align: center;
            width: 330px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 40px;
            background: #fafafa;
        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 40px;
            background: #fafafa;
            i{
                font-size: 16px;
            }
        }
    }
    /deep/.el-textarea{
        display: unset;
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }
    .el-dropdown {
        margin: 0px 0px 5px 0px;
        color: #fff;
    }
    .coll-title{
        width: 120px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background: #409eff;
        border-radius:0 20px 0 20px ;
        color: #ffffff;
    }
    .col{
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 0;
            width: 330px;
            background:#F2F8FE;
            min-height: 700px;
            border-radius: 5px;
        }
    }
    .editModal{
        /deep/.el-input__inner{
            height: 32px !important;
            line-height: 32px !important;
            width: 330px !important;
        }

    }
    ::-webkit-scrollbar {
        width: 0 !important;
    }
    ::-webkit-scrollbar {
        width: 0 !important;height: 0;
    }
    #red{
        color: red !important;
    }
    .xx{
        background: #F6F7FA url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxsaW5lIHgxPSIwIiB5MT0iMCIgeDI9IjEwMCUiIHkyPSIxMDAlIiBzdHJva2U9IiNDRENFQ0UiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==) no-repeat 100% center;
    }
    .blue{
        color: #4472c4;
    }
    /*.flow .el-table--border .el-input__inner {*/
    /*    width: 100px !important;*/
    /*    height:  unset !important;*/
    /*    line-height: unset !important;*/

    /*}*/
    .el-table--border td,
    .el-table--border th {
        border-bottom: 1px solid #EBEEF5;
        height: 28px;
        text-align: center;
        padding: 5px 8px;
        line-height: 18px;
    }

    .el-table--border td,
    .el-table--border th {
        border-right: 1px solid  #EBEEF5;
    }

    .el-table--border thead th {
        background: #f5f7fa;
    }
    .ver{
        writing-mode: vertical-lr;
        font-size: 14px;
        font-weight: 500;
    }
    .red{
        color: #ea4132;
    }
    .bgRed{
        background:  #ea4132;
    }
    .greenx{
        background: #31a952 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxsaW5lIHgxPSIwIiB5MT0iMCIgeDI9IjEwMCUiIHkyPSIxMDAlIiBzdHJva2U9IiNDRENFQ0UiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==) no-repeat 100% center;
    }
    .green{
        color: #fff;
        background: #31a952;
    }
</style>
