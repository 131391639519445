<template>
    <div class="Map AMap drag" id="Map" :style="{height:height+'px'}">
        <div id="viewDiv" class="viewDiv" @contextmenu="folderMouseControl" @click.self="hidemenu">
            <div class="menu">
                <ul id="MapMouse" style="display: none" :style="{ left: menuX, top: menuY }">
                    <li @click.stop="clearScreen">
                        <i>
                            <img src="@/assets/mapimages/maptools/dtyj4.png" alt />
                        </i>
                        <span>清屏</span>
                    </li>
                    <li @click.stop="Choiceof">
                        <i>
                            <img src="@/assets/mapimages/maptools/dtyj5.png" alt />
                        </i>
                        <span>选择</span>
                    </li>
                    <li @click.stop="mapMove">
                        <i>
                            <img src="@/assets/mapimages/maptools/dtyj2.png" alt />
                        </i>
                        <span>移动</span>
                    </li>
                    <li @click.stop="goTo">
                        <i>
                            <img src="@/assets/mapimages/maptools/dtyj3.png" alt />
                        </i>
                        <span>全屏</span>
                    </li>
                </ul>
            </div>

            <!-- 查询栏 -->
<!--            <div id="titleDiv" class="esri-widget">-->
<!--                <Search :reSearchStatus="reSearch"/>-->
<!--            </div>-->
        </div>



        <!-- 二三维切换 -->
        <div id="infoDiv">
            <input class="esri-component esri-widget--button esri-widget esri-interactive" type="button" @click="switchView" id="switch-btn" value="3D" />
        </div>

        <div id="layersDiv" class="esri-widget" v-show="layerStyleControlShow">
            <div id="description">
                <span id="sliderValue">地图图层显隐</span>
            </div>
            <div id="legendDiv">
                <!-- 图层显隐 -->
                <LayersShow />
            </div>
        </div>
<div class="toolsTop" v-if="!isLock">
    <div class="bj" @click="drawSquire"><i class="el-icon-s-flag"></i>路线绘制</div>
    <div v-if="dialogFormVisible" style="display: flex;align-items: center;justify-content: center;margin-left: 10px">
        <el-button size="mini" class="button" type="primary" @click="saveArea">保存</el-button>
        <el-button size="mini" class="button" @click="closeEdit">取消</el-button>
    </div>
    <div v-if="showClear" style="margin-left: 10px">
        <el-popconfirm style="margin:0;display: inline-block;" confirm-button-text='确认' @confirm="clearRings()" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否清除所画路线？清除后不可恢复">
            <template #reference>
                <el-button size="mini" class="button" type="primary">清除</el-button>
            </template>
        </el-popconfirm>
    </div>
</div>
        <!-- 工具栏 -->

        <!-- 测量工具栏 -->
        <Meature v-show="toolbarDivShow" @distanceMeasurement="distanceMeasurement" @areaMeasurement="areaMeasurement" @clearMeasurements="clearMeasurements" />

        <!-- 坐标定位 -->
        <Coordinate v-show="coordinateShow" @position="position" />

        <!-- 桩号定位 -->
        <PilePoint v-show="PilePointShow" v-on:poptemplate="poptemplate" v-on:animation="animation" :token="token" />

        <!-- 底图切换 -->
        <LayerSwitch @switchLayer="switchLayer" />

        <div class="loading" :class="{ loadingActive: !loading }">
            <i class="d-center">
                <img src="@/assets/mapimages/new/map-loading.gif" alt />
            </i>
            <span>正在获取</span>
        </div>

        <!-- 弹窗 -->



        <!--        dialog-->

<!--            <div class="drag_box" v-drag v-if="dialogFormVisible">-->
<!--&lt;!&ndash;                <div class="bt">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div>备注</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div @click="closeEdit" style="cursor: pointer"><i class="el-icon-close"></i></div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <div class="bc">-->
<!--&lt;!&ndash;                    <el-input&ndash;&gt;-->
<!--&lt;!&ndash;                            style="margin-top: 10px"&ndash;&gt;-->
<!--&lt;!&ndash;                            type="textarea"&ndash;&gt;-->
<!--&lt;!&ndash;                            :rows="5"&ndash;&gt;-->
<!--&lt;!&ndash;                            placeholder="请输入备注"&ndash;&gt;-->
<!--&lt;!&ndash;                            v-model="textarea">&ndash;&gt;-->
<!--&lt;!&ndash;                    </el-input>&ndash;&gt;-->
<!--                    <div v-if="roadModel.ZJYJ != 1" style="display: flex;align-items: center;justify-content: center;margin-top: 10px">-->
<!--                        <el-button size="mini" class="button" type="primary" @click="saveArea">保存</el-button>-->
<!--                        <el-button size="mini" class="button" @click="closeEdit">取消</el-button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

    </div>
</template>
<script>
    import { loadCss, loadModules } from "esri-loader";
import Meature from "@/components/AMap/Meature.vue"; //测量工具栏
    import LayerSwitch from "@/components/AMap/LayerSwitch.vue"; //图层选择
import LayersShow from "@/components/AMap/LayersShow.vue"; //图层列表
import Coordinate from "@/components/AMap/Coordinate.vue"; //坐标定位
import PilePoint from "@/components/AMap/PilePoint.vue"; //坐标定位
// import Search from "./Search.vue"; //查询工具栏
import { debounce } from "@/plugins/common.js";
import {
    apiDomainCss,
    apiDomainJs,
    RestUrl,
    BgUrl,
    LdUrl,
    trafficUrl,
    QueryUrl,
    DiMaoUrl,
    YingXiangUrl,
    amapImageUrl,
    amapStreetUrl,
    tdtUrl,
    planUrl,
    IdentifyByLXBMUrl,
} from "../../map/url";
import { GeoJSON, WFS } from "ol/format";
import {
    subLayerIsVisible,
    createTintLayer,
    createTitle,
    sortNumbers,
    calculateAngle,
} from "../../map/methods";

// import "../../assets/mapcss/global.less";
import "../../assets/mapcss/mappop.less";
import "../../assets/mapcss/map.less";
    import {bgLayer} from "../../js/map/layer";
export default {
    props: ["dialogData",'isLock'],
    name: "App",
    components: {
        Meature,
        LayerSwitch,
        LayersShow,
        Coordinate,
        PilePoint,
    },
    //注册局部组件指令
    directives: {
        drag: function (el) {
            let dragBox = el; //获取当前元素
            dragBox.onmousedown = e => {
                //算出鼠标相对元素的位置
                let disX = e.clientX - dragBox.offsetLeft;
                let disY = e.clientY - dragBox.offsetTop;
                document.onmousemove = e => {
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let left = e.clientX - disX;
                    let top = e.clientY - disY;
                    //移动当前元素
                    dragBox.style.left = left + "px";
                    dragBox.style.top = top + "px";
                };
                document.onmouseup = e => {
                    //鼠标弹起来的时候不再移动
                    document.onmousemove = null;
                    //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
                    document.onmouseup = null;
                };
            };
        }
    },
    data() {
        return {
            showClear: false,
            chooseIndex:0,
            reSearch: false,
            showColor: false,
            showLabel: false,
            labelList:[],
            tagHeight: document.body.offsetHeight - 185, //表格高度
            labelTitle:'',
            colorObj:{},
            showTag: false,
            geometryEngine:null,
            sketch:null,
            disabled: true,
            squireView:null,
            squireView1:null,
            move:null,
            sketchViewModel:null,
            rings:[],
            textarea:'',
            dialogFormVisible: false,
            height:document.documentElement.clientHeight - 80,
            activeId: 0,
            roadShow: false,
            roadChildShow: true,
            roadModel: {},
            queryShow: true, //查询结果显隐
            coordinateShow: false, //坐标定位显隐
            PilePointShow: false, //桩号定位显隐
            bgLayer: null,
            dmLayer: null,
            yxLayer: null,
            ldLayer: null,
            trafficLayer: null,
            planLayer: null,
            TintLayer: null,
            amapImageLayer: null,
            amapStreetLayer: null,
            tdtLayer: null,
            map: null,
            mapView: null,
            sceneView: null,
            activeView: null,
            legend: null,
            draw: null,
            mapChange: null,
            activeWidget: null, //测量动态对象
            toolbarDivShow: false, //测量工具栏显隐
            switchLayerIndex: 1, //底图切换索引
            mapSelected: false, //地图模式
            menuX: 0,
            menuY: 0,
            currentLocationIndex: 0,
            legendHide: false, //图例隐藏
            sketchHide: false, //地图编缉工具隐藏
            loading: false, //正在加载中
            layerStyleControlShow: false, //图层显隐
            token: "", //GIS TOKEN
            LkxzShow: false, //路况图层显隐
            GeometryEngine: null,//画框
            actions: null,//画框
            imgCoord: require("@/assets/mapimages/maptools/BluePin1LargeB.png"), //定位图标
            img1: require("@/assets/mapimages/maptools/arrow.png"),
            img3: require("@/assets/mapimages/new/infobox-close.png"),
            img4: require("@/assets/mapimages/new/ic_location_blue.png"),
            img5: require("@/assets/mapimages/new/ic_info.png"),
            img6: require("@/assets/mapimages/ic_wrong.png"),

            popupData: {}, //弹出框数据

            drawAction: null,
            sugList:[],
            measurement: null,
            webMercatorUtils: null,
            Graphic: null,
            QueryTask: null,
            Query: null,
            Point: null,
            watchUtils: null,
            IdentifyParameters: null,
            Extent: null,
            SpatialReference: null,
            Polygon: null,
            find: null,
            FindParameters: null,
            updateZjyj: false,
            findData: [],
        };
    },
    watch:{
        dialogData:{
            handler(val){
                console.log(val.XZFW)
                if(val.XZFW ){
                    this.updateZjyj = true
                    if(JSON.parse(val.XZFW).length != 0){
                        this.showClear = true
                    }
                }else{
                    this.updateZjyj = false
                }
            },
            immediate: true
        },
    },
    methods: {
        clearRings(){
            this.rings = []
            this.saveArea()
            this.showClear = false
            this.squireView.graphics.removeAll();

        },
        reSearchSate(){
            console.log('chongzhi ')
            this.reSearch = false
        },
        getTagList(){
            var that = this
            this.http.post('/api/Land_road_tag_base/GetPageData',{page:1,rows:1000,}).then(res=>{
                console.log(res)
                this.labelList = res.rows
                that.$nextTick(() => {
                    res.rows.forEach((item, index) => {
                        // if (!item.canChoose) {
                        //     // 默认被选中且禁用
                        //     this.$refs.multipleTable.toggleRowSelection(item, true);  // 如果只需要禁用 不需要这行
                        // }
                        //  selectReady 是默认要选中的数据
                        if (that.saveTag.length > 0) {
                            that.saveTag.forEach((obj, key) => {
                                if (item.ID == obj.TagId) { // 如果匹配上  代表当前角色下的用户  高亮选中
                                    console.log('22222222222')
                                    this.$refs.multipleTable.toggleRowSelection(item);
                                }
                            })
                        }
                    })
                    // this.chek = rows.TagList

                })

            })
        },
        addLabel() {
            this.colorObj = {};
            this.labelTitle = "新增标签";
            this.editColorStatus = true
            this.showColor = true;
        },
        saveColor() {
            if(this.colorObj.BQWZ == '' || this.colorObj.BQWZ == undefined){
                this.$message.error('请输入标签文字')
                return false
            }
            if(this.colorObj.BQYS == '' || this.colorObj.BQYS == undefined){
                this.$message.error('请选择颜色')
                return false
            }
            console.log(this.ID)
            var params = {
                id:'00000000-0000-0000-0000-000000000000',
                bqwz:this.colorObj.BQWZ,
                bqys:this.colorObj.BQYS
            }
            if(this.editColorStatus){
                params.id =  '00000000-0000-0000-0000-000000000000'
            }else{
                params.id =  this.colorObj.ID
            }
            if(this.editColorStatus){
                this.http.post("/api/Land_road_tag_base/AddLandRoadTagBase", params).then((res) => {
                    if(res.status){
                        this.$message.success('标签添加成功')
                        this.showColor = false
                        this.getTagList()
                        console.log( this.labelList)
                        // this.showLabel = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }else{
                this.http.post("/api/Land_road_tag_base/UpdateLandRoadTagBase", params).then((res) => {
                    if(res.status){
                        this.$message.success('保存成功')
                        this.showColor = false
                        this.getTagList()
                        // this.showLabel = false
                        this.reSearch = true
                        console.log(this.reSearch)
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }
            console.log("保存颜色");
        },
        changeColor(val) {
            console.log(val);
        },
        chooseColor(row) {
            console.log(row);
            this.labelTitle = "编辑标签";
            this.colorObj = JSON.parse(JSON.stringify(row));
            this.showColor = true;
        },
        openLabel(rows) {
            var that = this
            console.log(rows)
            this.ID = rows.SSWGHXMID
            console.log(this.SSWGHXMID)
            this.showLabel = true;
            this.saveTag = rows.TagList
            this.editColorStatus = false
            this.getTagList()
            if(!rows.ID){
                this.http.post('/api/Land_road/AddLandRoad',rows).then(res=>{
                    console.log(res)
                    if(res.status){
                        this.showLabel = true;
                        this.search()
                    }else{
                        this.$message.error(res.message)

                    }
                })
            }
        },
        saveLable() {
            console.log(this.chek)
            this.chek.map((item,index)=>{
                item.TagId = item.ID
            })
            // this.getTagList()
            this.http.post("/api/Land_road_tag/BindLandRoadTag", {xmid:this.ID,tagList:this.chek}).then((res) => {
                if(res.status){
                    console.log(this.reSearch )
                    this.$message.success('绑定成功')
                    this.reSearch = true
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        deletedLabel(row) {
            console.log(row)
            this.http.post('/api/Land_road_tag_base/DeleteLandRoadTagBase',row).then(res=>{
                if(res.status){
                    this.$message.success('删除成功')
                    this.getTagList()
                    this.search()
                    // this.showColor = false
                    // this.showLabel = false
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        checkSelectable(val) {
            this.chek = val;
        },
        selectLabel(val) {
            console.log(val);
            this.chek = val;
        },
        //获取详情内意见列表
        getList(ID){
            var postData = {
                page: 1,
                rows: 1000,
                Sort: "XMXH",
                Order: "asc",
                wheres: JSON.stringify([{
                    Name: "XMID",
                    Value: ID,
                    DisplayType: "equal",
                }])
            }
            if(!ID){
                // this.$message.warning('暂无信息')
                this.sugList = []
                return false
            }
            this.http.post("/api/Land_road_flag/GetPageData", postData, "正在加载，请稍候...").then((res) => {
                    if (res.status == 0) {
                        this.sugList = res.rows
                    }
                })
        },
        //保存区域
        saveArea(){
            var params = {
                ID:this.roadModel.ID,
                XZFW:JSON.stringify(this.rings),
                EarlyID:this.roadModel.ID,
            }
            console.log(params)

            if (this.updateZjyj){
                params.ZJYJ = this.textarea
                params.ID = this.roadModel.QQID
                console.log(params)
                this.http.post('/api/Land_road_flag_early/UpdateLandRoadFlag',params).then(res=>{
                    if(res.status){
                        this.$message.success('保存成功')
                        this.textarea = ''
                        this.dialogFormVisible = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }else{
                // if(this.textarea == ''){
                //     this.$message.warning('请输入意见')
                //     return false
                // }
                if(this.rings.length == 0){
                    this.$message.warning('请画出规划区域')
                    return false
                }
                this.http.post('/api/Land_road_flag_early/AddLandRoadFlag',params).then(res=>{
                    if(res.status){
                        this.$message.success('保存成功')
                        this.dialogFormVisible = false
                        this.move.remove()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }
            // if ()

        },
        closeEdit(){
            console.log(this.dialogData.XZFW)
            this.dialogFormVisible = false
            if (!this.dialogData.XZFW){
                this.squireView.graphics.removeAll();
            }
            this.dialogFormVisible = false
            this.move.remove()
        },
        //关闭查询结果栏
        closeQueryResult() {
            this.queryShow = false;
        },
        /**
         * 查询
         */
        doFind(layerIds, searchText, postData) {
            // console.log(this.planLayer);
            // this.planLayer.setDefinitionExpression("汇总序号 = 118");
            this.loading = true;
            this.queryShow = false;
            this.findData = [];
            // this.planLayer.findSublayerById(1).definitionExpression = "XMMC in ('省道连崟至兴华公路新林至兴华段','省道四季屯至鹤岗公路汤旺河至乌伊岭段')",

            // console.log(postData, 333);
            this.http
                .post(
                    "/api/View_land_road/GetData",
                    postData,
                    "正在加载，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        // var ddd = [];
                        // res.rows.map((train) => {
                        //     ddd.push("'" + train.XMMC + "'");
                        // });
                        // ddd = [...new Set(ddd)];
                        //
                        // // console.log(ddd, ddd.join(","));
                        //
                        // this.planLayer.findSublayerById(
                        //     1
                        // ).definitionExpression =
                        //     "XMMC in (" +
                        //     ddd.join(",") +
                        //     ") or BZ in (" +
                        //     ddd.join(",") +
                        //     ")";
                        // this.planLayer.findSublayerById(
                        //     0
                        // ).definitionExpression =
                        //     "XMMC in (" +
                        //     ddd.join(",") +
                        //     ") or BZ in (" +
                        //     ddd.join(",") +
                        //     ")";
                        // this.ChoicePlan();

                        this.findData = res.rows;
                        this.roadModel = res.rows[this.chooseIndex]
                        this.loading = false;
                        this.queryShow = true;
                    }
                });

            // var params = new this.FindParameters({
            //     layerIds: layerIds,
            //     searchText: searchText,
            //     returnGeometry: true,
            // });
            // console.log(params);
            // this.find
            //     .find(QueryUrl, params)
            //     .then(this.showResults)
            //     .catch(this.rejectedPromise);
        },
        createPolygonGraphic(vertices) {
            if (vertices.length < 2) {
                return;
            }
            this.squireView.graphics.removeAll();

            var rings = [
                vertices[0],
                [vertices[0][0], vertices[1][1]],
                vertices[1],
                [vertices[1][0], vertices[0][1]],
            ];
            let polygon = {
                type: "polygon", // autocasts as Polygon
                rings: [rings],
                spatialReference: this.activeView.spatialReference,
            };

            let graphic = new this.Graphic({
                geometry: polygon,
                symbol: {
                    type: "simple-fill", // autocasts as SimpleFillSymbol
                    color: "rgba(255,255,255,0.2)",
                    style: "solid",
                    outline: {
                        // autocasts as SimpleLineSymbol
                        color: "#ECFF40",
                        width: 1,
                    },
                },
            });
            //   this.activeView.graphics.add(graphic);
            this.squireView.graphics.add(graphic);
            this.rings = rings
        },
        showSketch() {
            this.sketchHide = true;
            if (this.activeView) {
                this.activeView.ui.padding = { top: 60, right: 10 };
                this.activeView.ui.add(this.editor, "top-right");
                this.FeatureEditShow = true;
                // this.mapMove();
                // this.clearScreen();
            } else {
                this.activeView.ui.remove(this.activeView);
                this.FeatureEditShow = false;
            }
        },
         measureLine(vertices) {
             if (vertices.length < 2) {
                 return;
             }
             this.squireView.graphics.removeAll();
             let polygon = {
                 type: "polyline", // autocasts as Polygon
                 paths: vertices,
                 spatialReference: this.activeView.spatialReference,
             };

             let graphic = new this.Graphic({
                 geometry: polygon,
                 symbol: {
                     type: "simple-line", // autocasts as SimpleFillSymbol
                     color: "red",
                     style: "solid",
                     width: 3,
                     outline: {
                         // autocasts as SimpleLineSymbol
                         color: "#ECFF40",
                         width: 10,
                     },
                 },
             });
             //   this.activeView.graphics.add(graphic);
             this.squireView.graphics.add(graphic);
             this.rings = vertices
    },
        drawSquire(){
            this.dialogFormVisible = true
            this.textarea = ''
            var _this = this;
            // 获取焦点
            this.actions = this.draw.create("polyline", {mode: "hybrid"});
            // this.actions = this.draw.create("polygon");
            this.actions.on("vertex-add", function (evt) {
                // _this.createPolygonGraphic(evt.vertices);
                _this.measureLine(evt.vertices);
            });
            // Fires when the pointer moves over the view
           this.move =  this.actions.on("cursor-update", function (evt) {
                // _this.createPolygonGraphic(evt.vertices);
               _this.measureLine(evt.vertices);

           });
            // 按ctrl+c撤销一个点的时候触发
            this.actions.on("vertex-remove", function (evt) {
                // _this.createPolygonGraphic(evt.vertices);
                _this.measureLine(evt.vertices);

            });
            //双击完成的时候触发
            this.actions.on("draw-complete", function (evt) {
                // _this.createPolygonGraphic(evt.vertices);
                _this.measureLine(evt.vertices);

            });



            // this.sketch.on("create", function(event) {
                // check if the create event's state has changed to complete indicating
                // the graphic create operation is completed.
                // if (event.state === "complete") {
                //     // remove the graphic from the layer. Sketch adds
                //     // the completed graphic to the layer by default.
                //     this.activeView.remove(event.graphic);
                //
                //     // use the graphic.geometry to query features that intersect it
                //     this.createPolygonGraphic(event.graphic.geometry);
                // }
            // });
        },
        gotoMap(cx,cy){
            console.log(this.webMercatorUtils)
            var x = cx/20037508.34*180;
            var y = cy/20037508.34*180;
            y = 180/Math.PI*(2*(Math.exp(y*Math.PI/180))-Math.PI/2);
            console.log(cx,cy,x,y)
            this.activeView.goTo({
                target: [cx,cy],
                zoom: 10,
                spatialReference: {wkid: 102100}
            }, {
                duration: 10000, //视角跳转时长为2秒，
            }).then(function () {
                console.log("跳转完成");
            })
        },
        queryLocation(graphic) {
            if (graphic.geometry.type == "polyline") {
                var center, arrowStartIndex, arrowEndIndex; //中心点坐标
                var pc = graphic.geometry.paths[0];
                if (pc.length % 2 == 0) {
                    arrowEndIndex = Math.round(pc.length / 2);
                    arrowStartIndex = Math.round(pc.length / 2 - 1);

                    var extent = new this.Extent(
                        pc[arrowStartIndex][0],
                        pc[arrowStartIndex][1],
                        pc[arrowEndIndex][0],
                        pc[arrowEndIndex][1],
                        new this.SpatialReference({ wkid: 3857 })
                    );
                    center = [extent.center.x, extent.center.y];
                } else {
                    arrowEndIndex = Math.round(pc.length / 2 + 1);
                    arrowStartIndex = Math.round(pc.length / 2 - 1);
                    center = pc[Math.round(pc.length / 2)];
                }
                this.activeView.goTo(graphic.geometry.extent);
            } else {
                graphic.pop = {
                    x: graphic.geometry.x,
                    y: graphic.geometry.y,
                };
                graphic.attr = {
                    x: graphic.geometry.x,
                    y: graphic.geometry.y,
                };
                this.activeView.graphics.add(graphic);
                this.activeView.goTo({
                    target: graphic.geometry,
                    zoom: 1,
                });
            }

        },
        reviewSquire(res){
            console.log(res,JSON.parse(res.XZFW),'xuanzhifanwei')
            this.textarea =res.ZJYJ
            this.saveParams = res
            // this.gotoMap(JSON.parse(res.XZFW)[0][0],JSON.parse(res.XZFW)[0][1])
            let polygon = {
                type: "polyline", // autocasts as Polygon
                paths: JSON.parse(res.XZFW),
                spatialReference: this.activeView.spatialReference,
            };
            // 生成绘制的图形
            let graphic = new this.Graphic({
                geometry: polygon,
                symbol: {
                    type: "simple-line", // autocasts as SimpleFillSymbol
                    color: "red",
                    style: "solid",
                    width: 3,
                    outline: {
                        // autocasts as SimpleLineSymbol
                        color: "#ECFF40",
                        width: 10,
                    },
                },
            });
            this.queryLocation(graphic)
            // 将绘制的图形添加到view
            this.squireView.graphics.add(graphic);
        },
        // reviewSquire(res){
        //     this.updateZjyj = true
        //     this.textarea =res.ZJYJ
        //     this.activeView.graphics.remove(graphic);
        //     this.saveParams = res
        //     console.log( this.activeView.graphics,JSON.parse(res.XZFW))
        //     let polygon = {
        //         type: "polygon", // autocasts as Polygon
        //         rings: [JSON.parse(res.XZFW)],
        //         spatialReference: this.activeView.spatialReference,
        //     };
        //     // var rectangle = new this.Polygon({
        //     //     hasZ: false,
        //     //     hasM: false,
        //     //     rings: [JSON.parse(res.XZFW)],
        //     //     spatialReference: this.activeView.spatialReference,
        //     // });
        //     // 生成绘制的图形
        //     var graphic = new this.Graphic({
        //         geometry: polygon,
        //         symbol: {
        //             type: "simple-fill", // autocasts as new SimpleFillSymbol()
        //             color: "rgba(255,255,255,0.2)",
        //             // style: "backward-diagonal",
        //             style: "solid",
        //             outline: {
        //                 // autocasts as new SimpleLineSymbol()
        //                 color: "#ECFF40",
        //                 width: 1,
        //             },
        //         },
        //     });
        //     // 将绘制的图形添加到view
        //     this.squireView.graphics.add(graphic);
        // },
        showResults(response) {
            var results = response.results;
            results.forEach((element) => {
                var item = {};
                item.layerId = element.layerId;
                item.layerName = element.layerName;
                item.value = element.value;
                item.feature = element;
                item.id =
                    element.layerId +
                    "-" +
                    element.feature.attributes["OBJECTID"];
                this.findData.push(item);
            });

            this.queryShow = true;
            this.loading = false;
        },
        rejectedPromise(error) {
            console.error("Promise didn't resolve: ", error.message);
            this.loading = false;
        },
        changeRadio(val){
            if (val == 1){
                this.disabled = true
            }else{
                this.disabled = false
            }
        },
        location(feature,index) {
            this.chooseIndex = index
            var that = this;
            this.roadModel = feature;
            // if(feature.SSWGHXMMC == '' || feature.SSWGHXMMC == null){
            //     return false
            // }

            // this.clearScreen();
            this.activeView.graphics.removeAll();
            this.squireView.graphics.removeAll();
            this.squireView1.graphics.removeAll();
            // var graphic = feature.feature;
            var where = "";
            var url = planUrl;
            console.log(feature.SSWGHXMMC,'feature.SSWGHXMMC')
            // "http://39.100.134.93:6080/arcgis/rest/services/plan/MapServer/";
            if (feature.GLLX == "高速公路") {
                where = "XMMC = '" + feature.SSWGHXMMC + "'";
                url = url + "/1";
            } else {
                if(feature.SSWGHXMMC!= null){
                    where = "XMMC = '" + feature.SSWGHXMMC + "'";
                }else{
                    where = "XMMC = '" + feature.XMMC + "'";
                }
                url = url + "/0";
            }
            this.disabled = this.roadModel.ZJYJ == 1 ?  true : false
            this.roadShow = true;
            this.roadChildShow = true;
            var queryTask = new this.QueryTask(url);
            var query = new this.Query();
            query.where = where;
            query.outSpatialReference = { wkid: 3857 };
            query.returnGeometry = true;
            query.outFields = ["*"];
            queryTask.execute(query).then(that.showResultsLX);

            // if (graphic.geometry.type == "polyline") {
            //     var center, arrowStartIndex, arrowEndIndex; //中心点坐标
            //     var pc = graphic.geometry.paths[0];
            //     if (pc.length % 2 == 0) {
            //         arrowEndIndex = Math.round(pc.length / 2);
            //         arrowStartIndex = Math.round(pc.length / 2 - 1);

            //         var extent = new this.Extent(
            //             pc[arrowStartIndex][0],
            //             pc[arrowStartIndex][1],
            //             pc[arrowEndIndex][0],
            //             pc[arrowEndIndex][1],
            //             new this.SpatialReference({ wkid: 3857 })
            //         );
            //         center = [extent.center.x, extent.center.y];
            //     } else {
            //         arrowEndIndex = Math.round(pc.length / 2 + 1);
            //         arrowStartIndex = Math.round(pc.length / 2 - 1);
            //         center = pc[Math.round(pc.length / 2)];
            //     }
            //     var centerPoint = {
            //         geometry: {
            //             type: "point",
            //             x: center[0],
            //             y: center[1],
            //             spatialReference: { wkid: 3857 },
            //         },
            //     };
            //     var popGraphic = new this.Graphic(centerPoint);
            //     popGraphic.pop = {
            //         x: center[0],
            //         y: center[1],
            //     };
            //     popGraphic.attr = {
            //         x: center[0],
            //         y: center[1],
            //     };
            //     this.activeView.graphics.add(popGraphic);
            //     this.activeView.goTo(graphic.geometry.extent);
            // } else {
            //     graphic.pop = {
            //         x: graphic.geometry.x,
            //         y: graphic.geometry.y,
            //     };
            //     graphic.attr = {
            //         x: graphic.geometry.x,
            //         y: graphic.geometry.y,
            //     };
            //     this.activeView.graphics.add(graphic);
            //     this.activeView.goTo({
            //         target: graphic.geometry,
            //         zoom: 10,
            //     });
            // }

            // this.showFeature(feature);
        },
        /**
         * 图层显隐
         */
        achangeLayer(layers) {
            layers.forEach((layer) => {
                var filter = this.ldLayer.sublayers.filter(
                    (v) => v.id == layer.id
                );
                filter.forEach((f) => {
                    console.log(f,'不知道是啥')
                    f.visible = layer.visible;
                });
            });
        },
        //整线定位
        showResultsLX(featureSet) {
            console.log(featureSet,'featureSet')
            if (featureSet.features.length <= 0) {
                this.$message.warning("无查询结果");
                // this.roadShow = false;
                return false;
            }
            // if(this.findData.findIndex(r=>r.XMMC == featureSet))
            var resultFeatures = featureSet.features;
            for (var i in resultFeatures) {
                var graphic = resultFeatures[i];
                // this.roadModel = graphic.attributes;
                // console.log(this.roadModel, 678);
                // this.roadShow = true;
                // this.roadChildShow = true;
                this.showPlanFeature({ feature: graphic });
                // var geo = graphic.geometry;
                // if (geo.type == "polyline") {
                // var pc = graphic.geometry.paths[0];
                // var start = pc[0]; //起点坐标
                // var end = pc[pc.length - 1]; //止点坐标

                // var center; //中心点坐标
                // var arrowStartIndex, arrowEndIndex;

                // if (pc.length % 2 == 0) {
                //     arrowEndIndex = Math.round(pc.length / 2);
                //     arrowStartIndex = Math.round(pc.length / 2 - 1);
                //     var extent = new this.Extent(
                //         pc[arrowStartIndex][0],
                //         pc[arrowStartIndex][1],
                //         pc[arrowEndIndex][0],
                //         pc[arrowEndIndex][1],
                //         new this.SpatialReference({ wkid: 3857 })
                //     );
                //     center = [extent.center.x, extent.center.y];
                // } else {
                //     arrowEndIndex = Math.round(pc.length / 2 + 1);
                //     arrowStartIndex = Math.round(pc.length / 2 - 1);
                //     center = pc[Math.round(pc.length / 2)];
                // }

                // var startPoint = {
                //     geometry: {
                //         type: "point",
                //         x: start[0],
                //         y: start[1],
                //         spatialReference: { wkid: 3857 },
                //     },
                //     symbol: {
                //         color: [255, 0, 0],
                //         size: 10,
                //         type: "simple-marker",
                //     },
                // };

                // var endPoint = {
                //     geometry: {
                //         type: "point",
                //         x: end[0],
                //         y: end[1],
                //         spatialReference: { wkid: 3857 },
                //     },
                //     symbol: {
                //         color: [0, 128, 0],
                //         size: 10,
                //         type: "simple-marker",
                //     },
                // };

                // var centerPoint = {
                //     geometry: {
                //         type: "point",
                //         x: center[0],
                //         y: center[1],
                //         spatialReference: { wkid: 3857 },
                //     },
                // };

                // var gStart = new this.Graphic(startPoint);
                // var gEnd = new this.Graphic(endPoint);
                // var gCenter = new this.Graphic(centerPoint);
                // var angle = calculateAngle(
                //     pc[arrowStartIndex],
                //     pc[arrowEndIndex]
                // ); //计算图形符号角度
                // var arrowSymbol = {
                //     url: this.img1,
                //     height: "40px",
                //     width: "40px",
                //     type: "picture-marker",
                //     angle: angle,
                // };
                // gCenter.symbol = arrowSymbol; //设置图形符号
                // //设置文字符号，显示路段序列号

                // var textSymbol = {
                //     type: "text",
                //     text:
                //         graphic.attributes.Ldxlh ||
                //         graphic.attributes.ldxlh,
                //     color: [255, 255, 255],
                //     font: { size: 8 },
                //     xoffset: 0,
                //     yoffset: -3,
                // };

                // var gCenterFont = new this.Graphic(centerPoint);

                // gCenterFont.symbol = textSymbol;

                // graphic.symbol = {
                //     type: "simple-line",
                //     color: "black",
                //     width: 4,
                // };
                // this.activeView.graphics.add(graphic);

                // this.activeView.graphics.add(gStart);
                // this.activeView.graphics.add(gEnd);
                // this.activeView.graphics.add(gCenter);
                // this.activeView.graphics.add(gCenterFont);

                var fullExtent;
                if (i == 0) {
                    fullExtent = graphic.geometry.extent;
                } else {
                    fullExtent = fullExtent.union(graphic.geometry.extent);
                }
                // }
            }
            this.activeView.goTo(fullExtent);
            this.loading = false;
        },
        /**
         * 坐标定位
         */
        position(x, y) {
            console.log(this.webMercatorUtils)
            this.activeView.graphics.removeAll();
            if (x && y) {
                var point = {
                    geometry: {
                        type: "point",
                        x: x,
                        y: y,
                        spatialReference: { wkid: 4326 },
                    },
                };
                var mercatorPoint =
                    this.webMercatorUtils.geographicToWebMercator(
                        point.geometry
                    );
                var picSymbol = {
                    url: this.imgCoord,
                    height: "40px",
                    width: "40px",
                    type: "picture-marker",
                };
                var graphic = new this.Graphic({
                    geometry:  mercatorPoint,
                    symbol: picSymbol,
                });

                this.activeView.graphics.add(graphic);
                this.activeView.goTo({
                    target: graphic.geometry,
                    zoom: 10,
                });
            } else {
                this.$toastr("输入的参数不正确");
            }
        },
        /**
         * 国省道地图显隐
         */
        showGSD() {
            this.ldLayer.visible = !this.ldLayer.visible;
            this.trafficLayer.visible = false;
            this.LkxzShow = false;
        },
        /**
         * 路况地图显隐
         */
        showLk() {
            this.LkxzShow = !this.LkxzShow;
            if (this.LkxzShow) {
                this.ldLayer.visible = false;
                this.trafficLayer.visible = true;
            } else {
                // this.ldLayer.visible = true;
                this.trafficLayer.visible = false;
            }
        },
        /**
         * 图例显隐
         */
        showLegend() {
            this.legendHide = !this.legendHide;
            if (this.legendHide) {
                // this.activeView.ui.padding = { top: 60, right: 10 };
                this.activeView.ui.add(this.legend, "bottom-left");
            } else {
                this.activeView.ui.remove(this.legend);
            }
        },
        distanceMeasurement() {
            const type = this.activeView.type;
            this.measurement.activeTool =
                type.toUpperCase() === "2D" ? "distance" : "direct-line";
        },
        areaMeasurement() {
            this.measurement.activeTool = "area";
        },
        clearMeasurements() {
            this.measurement.clear();
        },
        //点击添加点动画
        animation() {
            if (this.activeView.graphics.items.length > 0) {
                this.activeView.graphics.items.forEach((v) => {
                    if ("attr" in v) {
                        var dom = document.getElementById("point" + v.uid + "");
                        var point = this.activeView.toScreen(
                            new this.Point({
                                x: v.attr.x,
                                y: v.attr.y,
                                spatialReference: {
                                    wkid: 3857,
                                },
                            })
                        );
                        if (!dom) {
                            dom = document.createElement("div");
                            dom.className = "pulse";
                            dom.id = "point" + v.uid;
                            document.getElementById("viewDiv").appendChild(dom);
                        }
                        dom.style = `left:${point.x - 10}px;top:${
                            point.y - 10
                        }px`;
                    }
                });
            }
        },
        poptemplate(value) {
            if (this.activeView.graphics.items.length > 0) {
                this.activeView.graphics.items.forEach((v) => {
                    if ("pop" in v) {
                        var div = document.getElementById("pop" + v.uid + "");
                        var point = this.activeView.toScreen(
                            new this.Point({
                                x: v.pop.x,
                                y: v.pop.y,
                                spatialReference: {
                                    wkid: 3857,
                                },
                            })
                        );
                        if (!div && typeof value == "string") {
                            div = document.createElement("div");
                            var div1 = document.createElement("div");
                            var div2 = document.createElement("div");
                            div2.className = "angle";
                            div.id = "pop" + v.uid;
                            div.className = "poptemplate";
                            document.getElementById("viewDiv").appendChild(div);
                            div.appendChild(div1);
                            div.appendChild(div2);
                            div1.innerHTML = value;
                        }
                        if (div) {
                            var height = div.offsetHeight;
                            div.style = `left:${point.x - 140}px;top:${
                                point.y - 10 - height
                            }px`;
                        }
                    }
                });
            }
        },
        /**
         * 底图切换
         */
        switchLayer(index) {
            this.bgLayer.visible = false;
            this.dmLayer.visible = false;
            this.yxLayer.visible = false;
            this.tdtLayer.visible = false;
            this.amapImageLayer.visible = false;
            this.amapStreetLayer.visible = false;
            this.switchLayerIndex = index;
            switch (index) {
                case 0:
                    this.dmLayer.visible = true;
                    break;
                case 1:
                    this.bgLayer.visible = true;
                    break;
                case 2:
                    this.yxLayer.visible = true;
                    break;
                case 3:
                    this.amapImageLayer.visible = true;
                    this.amapStreetLayer.visible = true;
                    break;
                case 4:
                    this.tdtLayer.visible = true;
                    break;
            }
        },
        /**
         * 测量桩号
         */
        measureClick(event) {
            //判断是否鼠标左键
            if (event.button != 0) {
                return false;
            }
            this.loading = true;
            this.clearScreen();
            var params = new this.IdentifyParameters();
            params.tolerance = 10;
            params.layerOption = "top";
            //定义一个数组存放可见的图层
            let VisibleLayers = [];
            //获取所有图层
            this.ldLayer.allSublayers.items.forEach((v) => {
                if (subLayerIsVisible(v, this.activeView.scale)) {
                    VisibleLayers.push(v.id);
                }
            });

            // params.layerIds = this.list2;
            params.layerIds = VisibleLayers.sort(sortNumbers);
            params.width = this.activeView.width;
            params.height = this.activeView.height;
            params.geometry = event.mapPoint;
            params.mapExtent = this.activeView.extent;
            // document.getElementById("viewDiv").style.cursor = "wait";

            var identifyTaskMeasure = new this.IdentifyTask(LdUrl);
            identifyTaskMeasure.execute(params).then((res) => {
                this.loading = false;
                this.popupData.results = [];
                var shuzu = res.results[0]; //数组 随便存一下 名字随便取的
                if (shuzu != undefined) {
                    this.popupData.results.push(shuzu);
                }
                this.popupData.results.map((result) => {
                    this.popupData.layerName = result.layerName;
                    if (
                        this.popupData.layerName === "国道" ||
                        this.popupData.layerName === "省道" ||
                        this.popupData.layerName === "县道" ||
                        this.popupData.layerName === "乡道" ||
                        this.popupData.layerName === "专用公路" ||
                        this.popupData.layerName === "村道"
                    ) {
                        let layerIndex;
                        this.$PileTable.forEach((v) => {
                            if (v.title == this.popupData.layerName) {
                                layerIndex = v.index;
                            }
                        });
                        let formData = {
                            LXBM: result.feature.attributes.LXBM,
                            mapX: event.mapPoint.x,
                            mapY: event.mapPoint.y,
                            resolution: this.activeView.resolution,
                            layerIndex: layerIndex,
                            Lxfxdm: result.feature.attributes.Lxfxdm,
                            Nd: this.$nd,
                            f: "pjson",
                        };
                        this.$http({
                            methods: "get",
                            url: IdentifyByLXBMUrl,
                            params: {
                                LXBM: formData.LXBM,
                                mapX: "M" + formData.mapX,
                                mapY: "M" + formData.mapY,
                                resolution: "M" + formData.resolution,
                                layerIndex: "M" + formData.layerIndex,
                                Lxfxdm: "_" + formData.Lxfxdm,
                                Nd: "_" + formData.Nd,
                                f: formData.f,
                                token: this.token,
                            },
                        })
                            .then((res) => {
                                var str = res.data.replace(/'/g, '"');
                                let data = JSON.parse(str);
                                if (data != {}) {
                                    data.LXMC = result.feature.attributes.LXMC;
                                    data.LXBM = result.feature.attributes.LXBM;
                                    data.Ldjsdj =
                                        result.feature.attributes.Ldjsdj;
                                    data.Ldlmlx =
                                        result.feature.attributes.Ldlmlx;
                                    this.pileMeasurePop(data);
                                }
                            })
                            .catch((error) => {
                                4;
                                console.log(error);
                                this.loading = false;
                            });
                    }
                });
            });
        },
        /**
         * 桩号定位结果
         */
        pileMeasurePop(res) {
            //桩号测量弹出框
            let graphic = res;
            var x = res.x;
            var y = res.y;
            var popPoint = {
                geometry: {
                    type: "point",
                    x: x,
                    y: y,
                    spatialReference: { wkid: 3857 },
                },
                symbol: {
                    color: "rgba(255,255,255,0)",
                    size: 0,
                    type: "simple-marker",
                    // style: "square"
                },
            };
            var popGraphic = new this.Graphic(popPoint);
            popGraphic.pop = {
                x: x,
                y: y,
            };
            this.activeView.graphics.add(popGraphic);
            var dianPoint = {
                geometry: {
                    type: "point",
                    x: x,
                    y: y,
                    spatialReference: { wkid: 3857 },
                },
                symbol: {
                    color: "rgba(255,255,255,0)",
                    size: 0,
                    type: "simple-marker",
                },
            };
            let gEnd = new this.Graphic(dianPoint);
            gEnd.attr = {
                x: x,
                y: y,
            };
            this.activeView.graphics.add(gEnd);
            var name = "测量";
            let title = createTitle(name, graphic);
            this.activeView.goTo({
                target: popPoint,
                speedFactor: 6,
                easing: "linear",
            });
            this.poptemplate(title);
            this.animation();
            this.loading = false;
        },

        //全屏
        goTo() {
            this.mapView.goTo({
                target: this.bgLayer.fullExtent,
            });
            this.mouseMenuState("none");
        },
        /**
         * 地图拾取
         */
        Choiceof() {
            this.activeView.graphics.removeAll();
            this.$refs.tools.mouseState = 2;
            this.mapSelected = true;
            this.mouseMenuState("none");

            if (this.mapChange != null) {
                this.mapChange.remove();
            }
            this.planLayer.findSublayerById(0).visible = false;
            this.planLayer.findSublayerById(1).visible = false;
            this.mapChange = this.activeView.on(
                "click",
                this.executeIdentifyTask
            );
        },
        ChoicePlan() {
            this.activeView.graphics.removeAll();
            this.$refs.tools.mouseState = 4;
            this.mapSelected = true;
            this.mouseMenuState("none");

            if (this.mapChange != null) {
                this.mapChange.remove();
            }
            // this.planLayer.visible = true;
            this.planLayer.findSublayerById(0).visible = true;
            this.planLayer.findSublayerById(1).visible = true;
            this.mapChange = this.activeView.on(
                "click",
                this.planExecuteIdentifyTask
            );
        },
        //地图移动
        mapMove() {
            this.activeView.graphics.removeAll();
            this.$refs.tools.mouseState = 1;
            this.mapSelected = false;
            // this.planLayer.visible = false;
            this.planLayer.findSublayerById(0).visible = false;
            this.planLayer.findSublayerById(1).visible = false;
            this.mouseMenuState("none");
        },
        //测量桩号
        mapMeasure() {
            this.activeView.graphics.removeAll();
            this.$refs.tools.mouseState = 3;
            this.mapSelected = false;
            this.mouseMenuState("none");
            if (this.mapChange != null) {
                this.mapChange.remove();
            }
            this.mapChange = this.activeView.on("click", this.measureClick);
        },
        mouseMenuState(state) {
            var mouseMenu = document.getElementById("MapMouse");
            if (mouseMenu) {
                mouseMenu.style = "display:" + state;
            }
        },
        //清屏
        clearScreen() {
            this.activeView.graphics.removeAll();
            //清空弹窗
            var infos = document.querySelectorAll(".poptemplate");
            if (infos.length > 0) {
                infos.forEach((v) => {
                    v.remove();
                });
            }

            //清空地图渲染动画
            var dom = document.querySelectorAll(".pulse");
            if (dom.length > 0) {
                dom.forEach((v) => {
                    v.remove();
                });
            }
            this.mouseMenuState("none");
        },
        //控制鼠标右键菜单出现位置
        folderMouseControl(e) {
            var obj = document.getElementById("viewDiv");
            function getAbsLeft(obj) {
                var l = obj.offsetLeft;
                while (obj.offsetParent != null) {
                    obj = obj.offsetParent;
                    l += obj.offsetLeft;
                }
                return l;
            }

            if (!this.sketchHide) {
                e.preventDefault();
                e.stopPropagation();
                var l = getAbsLeft(obj);

                e = e || event;
                this.mouseMenuState("block");
                this.menuX = e.pageX - l + "px";
                this.menuY = e.pageY - 20 + "px";
                return false;
            }
        },
        //IdentifyTask查询后定义弹出框数据
        executeIdentifyTask(event) {
            //判断是否鼠标左键
            if (event.button != 0) {
                return false;
            }
            this.clearScreen();
            if (this.mapSelected) {
                var params = new this.IdentifyParameters();
                params.tolerance = 10;
                params.layerOption = "top";
                params.returnGeometry = true;
                //定义一个数组存放可见的图层
                let VisibleLayers = [];
                //获取所有图层
                this.ldLayer.allSublayers.items.forEach((v) => {
                    if (subLayerIsVisible(v, this.activeView.scale)) {
                        VisibleLayers.push(v.id);
                    }
                });
                params.layerIds = VisibleLayers.sort(this.sortNumbers);
                params.width = this.activeView.width;
                params.height = this.activeView.height;
                params.geometry = event.mapPoint;
                params.mapExtent = this.activeView.extent;
                var identifyTask = new this.IdentifyTask(LdUrl);
                identifyTask.execute(params).then((res) => {
                    if (res.results.length == 0) {
                        this.$toastr("无查询结果");
                        return;
                    }

                    //弹窗点位
                    var popPoint = {
                        geometry: {
                            type: "point",
                            x: event.mapPoint.x,
                            y: event.mapPoint.y,
                            spatialReference: { wkid: 3857 },
                        },
                        symbol: {
                            color: "rgba(255,255,255,0)",
                            size: 0,
                            type: "simple-marker",
                            // style: "square"
                        },
                    };
                    var popGraphic = new this.Graphic(popPoint);
                    popGraphic.pop = {
                        x: event.mapPoint.x,
                        y: event.mapPoint.y,
                    };
                    this.activeView.graphics.add(popGraphic);
                    var feature = res.results[0];
                    this.showFeature(feature);
                });
            }
        },
        //点击地图 路线 或者 点 高亮
        showFeature(feature) {
            var graphic = feature.feature;
            var geo = graphic.geometry;
            var layerName = feature.layerName;
            var layerId = feature.layerId;
            if (geo.type == "point") {
                this.activeView.graphics.add(graphic);
                let title = createTitle(layerName, graphic);
                this.poptemplate(title);
                this.animation();
            }
            if (geo.type == "polyline") {
                if (layerName != "匝道") {
                    layerName = "公路";
                }
                var pc = graphic.geometry.paths[0];
                var start = pc[0]; //起点坐标
                var end = pc[pc.length - 1]; //止点坐标
                var center; //中心点坐标
                var arrowStartIndex, arrowEndIndex;

                if (pc.length % 2 == 0) {
                    arrowEndIndex = Math.round(pc.length / 2);
                    arrowStartIndex = Math.round(pc.length / 2 - 1);
                    var extent = new this.Extent(
                        pc[arrowStartIndex][0],
                        pc[arrowStartIndex][1],
                        pc[arrowEndIndex][0],
                        pc[arrowEndIndex][1],
                        new this.SpatialReference({ wkid: 3857 })
                    );
                    center = [extent.center.x, extent.center.y];
                } else {
                    arrowEndIndex = Math.round(pc.length / 2 + 1);
                    arrowStartIndex = Math.round(pc.length / 2 - 1);
                    center = pc[Math.round(pc.length / 2)];
                }
                var startPoint = {
                    geometry: {
                        type: "point",
                        x: start[0],
                        y: start[1],
                        spatialReference: { wkid: 3857 },
                    },
                    symbol: {
                        color: [255, 0, 0],
                        size: 10,
                        type: "simple-marker",
                    },
                };

                var endPoint = {
                    geometry: {
                        type: "point",
                        x: end[0],
                        y: end[1],
                        spatialReference: { wkid: 3857 },
                    },
                    symbol: {
                        color: [0, 128, 0],
                        size: 10,
                        type: "simple-marker",
                    },
                };

                var gStart = new this.Graphic(startPoint);
                var gEnd = new this.Graphic(endPoint);
                if (layerName == "公路") {
                    var centerPoint = {
                        geometry: {
                            type: "point",
                            x: center[0],
                            y: center[1],
                            spatialReference: { wkid: 3857 },
                        },
                    };

                    var gCenter = new this.Graphic(centerPoint);
                    var angle = calculateAngle(
                        pc[arrowStartIndex],
                        pc[arrowEndIndex]
                    ); //计算图形符号角度
                    var arrowSymbol = {
                        url: this.img1,
                        height: "40px",
                        width: "40px",
                        type: "picture-marker",
                        angle: angle,
                    };
                    gCenter.symbol = arrowSymbol; //设置图形符号
                    var textSymbol = {
                        type: "text",
                        text: graphic.attributes.Ldxlh || "1",
                        color: [255, 255, 255],
                        font: { size: 8 },
                        xoffset: 0,
                        yoffset: -3,
                    };

                    var gCenterFont = new this.Graphic(centerPoint);
                    gCenterFont.symbol = textSymbol;
                    graphic.symbol = {
                        type: "simple-line",
                        color: "black",
                        width: 4,
                    };
                }
                this.activeView.graphics.add(graphic);
                this.activeView.graphics.add(gStart);
                this.activeView.graphics.add(gEnd);
                if (layerName == "公路") {
                    this.activeView.graphics.add(gCenter);
                    this.activeView.graphics.add(gCenterFont);
                }

                var title = createTitle(layerName, graphic, layerId);
                this.poptemplate(title);
            }
        },
        //IdentifyTask查询后定义弹出框数据
        planExecuteIdentifyTask(event) {
            //判断是否鼠标左键
            if (event.button != 0) {
                return false;
            }

            // this.clearScreen();
            if (this.mapSelected) {
                var params = new this.IdentifyParameters();
                params.tolerance = 10;
                params.layerOption = "top";
                params.returnGeometry = true;
                //定义一个数组存放可见的图层
                let VisibleLayers = [];
                //获取所有图层
                this.planLayer.allSublayers.items.forEach((v) => {
                    VisibleLayers.push(v.id);
                });

                params.layerIds = VisibleLayers.sort(this.sortNumbers);
                params.width = this.activeView.width;
                params.height = this.activeView.height;
                params.geometry = event.mapPoint;
                params.mapExtent = this.activeView.extent;
                var identifyTask = new this.IdentifyTask(planUrl);
                identifyTask.execute(params).then((res) => {
                    if (res.results.length == 0) {
                        this.$toastr("无查询结果");
                        return;
                    }
                    //弹窗点位
                    // var popPoint = {
                    //     geometry: {
                    //         type: "point",
                    //         x: event.mapPoint.x,
                    //         y: event.mapPoint.y,
                    //         spatialReference: { wkid: 3857 },
                    //     },
                    //     symbol: {
                    //         color: "rgba(0,0,0,1)",
                    //         size: 0,
                    //         type: "simple-marker",
                    //         style: "square",
                    //     },
                    // };
                    // var popGraphic = new this.Graphic(popPoint);
                    // popGraphic.pop = {
                    //     x: event.mapPoint.x,
                    //     y: event.mapPoint.y,
                    // };
                    // this.activeView.graphics.add(popGraphic);
                    var feature = res.results[0];

                    // this.roadModel = feature.feature.attributes;
                    //    var xmxh = feature.feature.attributes.ldxlh.replace(/\b(0+)/gi, "");
                    var xh = feature.feature.attributes.OBJECTID;
                    console.log(JSON.stringify(feature.feature.attributes));
                    // console.log(JSON.stringify(this.roadModel));
                    // console.log(this.findData);
                    // console.log(feature.feature.attributes);

                    // if (!this.findData.find((r) =>r.XMMC == this.roadModel.BZ ||r.XMMC == this.roadModel.XMMC)) {
                    //     return false;
                    // }
                    if (this.findData.find((r) => r.XH == xh)) {
                        this.roadModel = this.findData.find((r) => r.XH == xh);
                        this.roadShow = true;
                        this.roadChildShow = true;
                    } else {
                        this.roadShow = false;
                    }

                    this.showPlanFeature(feature);
                });
            }
        },
        showPlanFeature(feature) {
            var graphic = feature.feature;
            // console.log(graphic);
            // console.log(graphic.geometry.type);
            var geo = graphic.geometry;
            var layerName = feature.layerName;
            var layerId = feature.layerId;
            // var title = createTitle(layerName, graphic, layerId);
            let sym = {
                type: "simple-fill", // autocasts as new SimpleFillSymbol()
                color: [0, 0, 0, 0],
                outline: {
                    color: [240, 29, 195],
                    width: "4",
                },
            };
            graphic.symbol = sym;
            this.activeView.graphics.add(graphic);

            // if (geo.type == "polyline") {
            //     let sym = {
            //         type: "simple-fill", // autocasts as new SimpleFillSymbol()
            //         color: [0, 0, 0, 0],
            //         outline: {
            //             color: [240, 29, 195],
            //             width: "4",
            //         },
            //     };
            //     graphic.symbol = sym;
            //     this.activeView.graphics.add(graphic);
            //     // this.poptemplate(title);
            // }
            // if (geo.type == "polygon") {
            //     let sym = {
            //         type: "simple-fill", // autocasts as new SimpleFillSymbol()
            //         color: [0, 0, 0],
            //         outline: {
            //             // autocasts as new SimpleLineSymbol()
            //             color: [240, 29, 195],
            //             width: "4",
            //         },
            //     };

            //     graphic.symbol = sym;
            //     this.activeView.graphics.add(graphic);
            //     // this.poptemplate(title);
            // }
        },
        //控制鼠标右键菜单存不存在
        hidemenu() {
            this.mouseMenuState("none");
            this.mapView.graphics.removeAll();
        },
        /**
         * 二三维切换
         */
        switchView() {
            // Clone the viewpoint for the MapView or SceneView
            const viewpoint = this.activeView.viewpoint.clone();
            // Get the view type, either 2d or 3d
            const type = this.activeView.type;
            this.clearMeasurements();
            this.activeView.container = null;
            this.activeView = null;
            // Set the view based on whether it switched to 2D MapView or 3D SceneView
            this.activeView =
                type.toUpperCase() === "2D" ? this.sceneView : this.mapView;

            if (type.toUpperCase() == "2D") {
                this.switchLayer(0);
            } else {
                this.switchLayer(1);
            }
            this.activeView.set({
                container: "viewDiv",
                viewpoint: viewpoint,
            });
            this.activeView.ui.add(this.measurement, "bottom-right");
            this.measurement.view = this.activeView;
            const switchButton = document.getElementById("switch-btn");
            switchButton.value = type.toUpperCase();
        },
        setVisiLayers() {
            this.ldLayer.sublayers = this.layers;
        },
        loadingState(state) {
            this.loading = state;
        },
    },
    mounted() {
        var that = this;

        /**
         * 整线选取
         */
        window.queryLx = function (val, id) {
            that.loading = true;
            that.clearScreen();
            var queryTask = new that.QueryTask(QueryUrl + id);
            var query = new that.Query();
            query.where = "LXBM  = '" + val + "'";
            query.outSpatialReference = { wkid: 3857 };
            query.returnGeometry = true;
            query.outFields = ["*"];
            queryTask.execute(query).then(that.showResultsLX);
        };
        /**
         * 关闭弹窗
         */
        window.closePop = function () {
            var infos = document.getElementsByClassName("poptemplate");
            if (infos.length > 0) {
                infos[0].remove();
            }
        };

        /**
         * 查询详情
         */
        window.showForm = function (guid, queryname) {
            console.log(guid, queryname);
        };

        loadCss(apiDomainCss);
        loadModules(
            [
                "esri/views/draw/MultipointDrawAction",
                "esri/layers/GraphicsLayer",
                "esri/Map",
                "esri/views/MapView",
                "esri/views/SceneView",
                "esri/request",
                "esri/layers/WebTileLayer",
                "esri/layers/BaseTileLayer",
                "esri/layers/MapImageLayer",
                "esri/layers/TileLayer",
                "esri/geometry/Point",
                "esri/Graphic",
                "esri/tasks/QueryTask",
                "esri/rest/support/Query",
                "esri/geometry/SpatialReference",
                "esri/geometry/Extent",
                "esri/Color",
                "esri/tasks/IdentifyTask",
                "esri/rest/find",
                "esri/rest/support/FindParameters",
                "esri/rest/support/IdentifyParameters",
                "esri/views/2d/draw/Draw",
                "esri/geometry/Polygon",
                "esri/core/watchUtils",
                "esri/identity/IdentityManager",
                "esri/identity/ServerInfo",
                "esri/widgets/Legend",
                "esri/widgets/Measurement",
                "esri/geometry/geometryEngine",//画框
                "esri/geometry/support/webMercatorUtils",
                "esri/widgets/Sketch",
                "esri/widgets/Editor",
            ],
            {
                url: apiDomainJs,
            }
        )
            .then(
                ([
                     MultipointDrawAction,
                     GraphicsLayer,
                    Map,
                    MapView,
                    SceneView,
                    request,
                    WebTileLayer,
                    BaseTileLayer,
                    MapImageLayer,
                    TileLayer,
                    Point,
                    Graphic,
                    QueryTask,
                    Query,
                    SpatialReference,
                    Extent,
                    Color,
                    IdentifyTask,
                    find,
                    FindParameters,
                    IdentifyParameters,
                    Draw,
                    Polygon,
                    watchUtils,
                    IdentityManager,
                    ServerInfo,
                    Legend,
                    Measurement,
                    webMercatorUtils,
                     GeometryEngine,//画框
                     Sketch,
                     Editor,
                 ]) => {
                    console.log(1212121212121212)
                    this.webMercatorUtils = webMercatorUtils;
                    this.Graphic = Graphic;
                    this.QueryTask = QueryTask;
                    this.Query = Query;
                    this.Point = Point;
                    this.watchUtils = watchUtils;
                    this.IdentifyParameters = IdentifyParameters;
                    this.IdentifyTask = IdentifyTask;
                    this.Extent = Extent;
                    this.SpatialReference = SpatialReference;
                    this.Polygon = Polygon;
                    this.find = find;
                    this.FindParameters = FindParameters;
                    this.geometryEngine = GeometryEngine;//画框
                    this.MultipointDrawAction = MultipointDrawAction;//画框
                    //背景图层
                    this.bgLayer = new TileLayer({
                        url: BgUrl,
                        visible: true,
                    });
                    //地貌图层
                    this.dmLayer = new TileLayer({
                        url: DiMaoUrl,
                        visible: false,
                    });

                    //影像图层
                    this.yxLayer = new TileLayer({
                        url: YingXiangUrl,
                        visible: false,
                    });
                    this.squireView = new GraphicsLayer({
                        container: "viewDiv",
                    })
                    this.squireView1 = new GraphicsLayer()
                    // this.sketch = new Sketch({
                    //     layer: GraphicsLayer,
                    //     view: this.activeView
                    // });
                    //获取Token
                    // identityManager.tokenValidity = 160; //设置token过期时间720分钟
                    ServerInfo.serverString = RestUrl + "/arcgis/rest/services"; //这里配置ArcGIS Server的REST服务地址
                    ServerInfo.tokenServiceUrl =
                        RestUrl + "/arcgis/tokens/generateToken"; //由于GIS Server和Portal联合了，所以使用Portal的token生成地址
                    // serverInfo.shortLivedTokenValidity = 720;//设置token过期时间720分钟
                    var userInfo = { username: "zzUser", password: "gis_1234" }; //这里填写Portal的用户和密码
                    // console.log("serverInfo", serverInfo);
                    var that = this;
                    IdentityManager.generateToken(ServerInfo, userInfo).then(
                        function (data) {
                            that.token = data.token;
                            window.localStorage.gisToken = data.token;
                            //注册Token，注册之后，在Portal里的所有资源，只要该用户由权限访问，就可以直接使用，之前的所有安全服务请求都将会把token值作为参数发送到服务器端
                            IdentityManager.registerToken({
                                server: RestUrl + "/arcgis/rest/services",
                                token: data.token,
                            });

                            //加载需要token的图层
                            //公路路段图层
                            that.ldLayer = new MapImageLayer({
                                url: LdUrl,
                                title: "公路",
                                sublayers: [
                                    {
                                        id: 17,
                                        visible: true,
                                    },
                                    {
                                        id: 18,
                                        visible: false,
                                    },
                                    {
                                        id: 22,
                                        visible: false,
                                    },
                                ],
                            });
                            //路况图层
                            that.trafficLayer = new MapImageLayer({
                                url: trafficUrl,
                                visible: false,
                            });

                            //规划公路图层
                            // that.planLayer = new MapImageLayer({
                            //     url: planUrl,
                            //     visible: false,
                            // });

                            that.planLayer = new MapImageLayer({
                                url: planUrl,
                                sublayers: [
                                    {
                                        id: 1,
                                        visible: false,
                                    },
                                    {
                                        id: 0,
                                        visible: false,
                                    },
                                ],
                            });
                            that.map.addMany([
                                that.squireView,
                                that.squireView1,
                                that.ldLayer,
                                that.trafficLayer,
                                that.planLayer,
                            ]);
                            console.log(that.ldLayer,'不知道是什么')

                            // console.log(that.map, 222);
                            //设置图例
                            that.legend = new Legend({
                                view: that.activeView,
                                layerInfos: [
                                    {
                                        layer: that.ldLayer,
                                        title: "公路按行政等级",
                                    },
                                    {
                                        layer: that.trafficLayer,
                                        title: "公路按路况评定",
                                    },
                                ],
                            });
                        },
                        function (err) {
                        }
                    );

                    //创建高德地图对象
                    this.TintLayer = createTintLayer(
                        BaseTileLayer,
                        Color,
                        request
                    );
                    // that.squireView = new GraphicsLayer({
                    //     visible: true,
                    // })
                    //高德影像
                    this.amapImageLayer = new this.TintLayer({
                        urlTemplate: amapImageUrl,
                        tint: new Color("#004FBB"),
                        title: "高德",
                        visible: false,
                    });

                    //高德街区
                    this.amapStreetLayer = new this.TintLayer({
                        urlTemplate: amapStreetUrl,
                        tint: new Color("#004FBB"),
                        title: "高德",
                        visible: false,
                    });

                    //天地图影像
                    this.tdtLayer = new WebTileLayer({
                        urlTemplate: tdtUrl,
                        subDomains: [
                            "t0",
                            "t1",
                            "t2",
                            "t3",
                            "t4",
                            "t5",
                            "t6",
                            "t7",
                        ],
                        copyright: "天地图影像",
                        visible: false,
                    });

                    this.map = new Map({
                        layers: [
                            this.bgLayer,
                            this.dmLayer,
                            this.yxLayer,
                            this.amapImageLayer,
                            this.amapStreetLayer,
                            this.tdtLayer,
                        ],
                    });

                    this.mapView = new MapView({
                        map: this.map,
                        container: "viewDiv",
                        constraints: {
                            rotationEnabled: false, //禁止旋转
                        },
                    });
                    this.View = new MapView({
                        map: this.map,
                        container: "suqareDiv",
                        constraints: {
                            rotationEnabled: false, //禁止旋转
                        },
                    });
                    const titleDiv = document.getElementById("titleDiv");
                    this.mapView.ui.add(titleDiv, "top-left");
                    // Create SceneView with similar extent to MapView
                    this.sceneView = new SceneView({
                        map: this.map,
                    });

                    this.activeView = this.mapView;
                    this.measurement = new Measurement();
                    /*矩形*/
                    this.draw = new Draw({
                        view: this.mapView,
                    });

                    //测量初始化
                    this.activeView.set({
                        container: "viewDiv",
                    });

                    this.activeView.ui.add(this.measurement, "bottom-right");
                    this.measurement.view = this.activeView;

                    //点击空白关闭鼠标右键菜单
                    document
                        .getElementById("viewDiv")
                        .addEventListener("click", () => {
                            this.mouseMenuState("none");
                        });

                    //地图缩放监听
                    this.watchUtils.when(
                        this.activeView,
                        "extent",
                        this.animation
                    );
                    this.watchUtils.when(
                        this.activeView,
                        "extent",
                        this.poptemplate
                    );

                    this.mapView.ui.remove("attribution"); //移除底部ESRI logo
                    this.mapView.ui._removeComponents(["zoom"]); //移除默认ui
                    setTimeout(()=>{
                        if (this.dialogData.XZFW){
                        this.reviewSquire(this.dialogData)
                        }
                    },1000)
                    this.location(this.dialogData)

                }
            )
            .catch((err) => {
                console.log("err", err);
            });

    },
};
</script>
<style>
.toolsTop{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 10vh;
}
.toolsTop .top_ri {

    top: 30px !important;
    right: 10px !important;
}
</style>
<style scoped lang="less">
#infoDiv {
    display: none;
    position: absolute;
    bottom: 15px;
    left: 15px;
}
#layersDiv input {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
}

#layersDiv {
    position: absolute;
    padding: 10px;
    width: 275px;
    top: 55px;
    right: 10px;
}
#sliderValue {
    margin-left: 10px;
    font-weight: bolder;
}

#legendDiv {
    width: 260px;
    margin-left: 10px;
    margin-top: 1px;
}
#description {
    padding: 10px 0 10px 0;
}
#titleDiv {
    background: none;
    box-shadow: 0 0 black;
    margin-top: 0px;
}

.panel-side {
    padding: 2px;
    width: 390px;
    height: 85%;
    position: absolute;
    background: white;
    top: 0;
    right: 0;
    margin-top: 50px;
    margin-right: 10px;
    z-index: 999;
}

#resultsDiv {
    overflow: auto;
    height: 100%;
}

.query_result {
    position: absolute;
    top: 101px;
    left: auto;
    width: 358px;
    margin-left: 15px;
    margin-top: 15px;
    display: flex;
    align-items: cfenter;
    justify-content: center;
    transition: all 0.5s ease;
    background: rgba(255, 255, 255, 0.8);
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom : 1px solid #DDDDDD;
    /*box-shadow: 0px 1px 5px 0px rgba(2, 40, 97, 0.15);*/
    border-radius: 3px;
    flex-wrap: wrap;
    padding: 0px 0 12px 0;

    .nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /*padding-bottom: 10px;*/

        .left_nav {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: rgba(0, 123, 255, 1);
                margin-right: 8px;

                &:first-child {
                    width: 4px;
                    height: 16px;
                    background: rgba(0, 123, 255, 1);
                    border-radius: 2px;
                }
            }
        }

        .right_nav {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;

            i {
                width: 18px;
                height: 16px;

                img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .main_result {
        width: 360px;
        height: 80vh;
        // display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: scroll;

        .item_result {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            // width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            .center_item {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 5px;
                width: calc(100%);
                .tag{
                    height: 24px;
                    border: 1px solid #C5DBFB;
                    border-radius: 4px;
                    color: #1D9EF0;
                    line-height: 24px;
                    padding: 0 13px;
                    font-size: 12px;
                    margin-right: 10px;
                }
                .left_item {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 2px;
                        img {
                            width: 11px;
                            height: 14px;
                            cursor: pointer;
                        }
                    }
                }
                .right_item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #747474;
                    i {
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right:2px;
                        img {
                            width: 16px;
                            height: 12px;
                        }
                    }
                    .gl{
                        color: #F56C6C;
                    }
                }
                .zjyj{
                    text-align: center;
                    width: 40px;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 2px 7px;
                    color: #fff !important;
                    margin-right: 9px;
                    height: 20px;
                    line-height: 20px;
                    background: rgba(245, 108, 108, 1);
                    border-radius: 12px;
                    }
                .title {
                    font-size: 14px;
                    width: 280px;
                    overflow: hidden;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #000000;
                    line-height: 2em;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    /*&:first-child {*/
                    /*    font-size: 14px;*/
                    /*    font-family: Microsoft YaHei;*/
                    /*    font-weight: bold;*/
                    /*    color: rgba(0, 0, 0, 1);*/
                    /*    cursor: pointer;*/
                    /*}*/
                }
            }
        }
        .active {
            color: rgb(0, 143, 212);
            .title {
                color: rgb(0, 143, 212) !important;
            }
        }
    }
}
.drag {

}
.drag_box {
    width:380px;
    /* 使用定位，脱离文档流 */
    position: fixed;
    top: 10%;
    left: 50%;
    margin-left: -180px;
    z-index: 2001;
    /* 鼠标移入变成拖拽手势 */
    cursor: move;
    box-shadow: 0px 0px 20px 1px #dddddd;
    .bt{
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 360px;
        height: 42px;
        background: rgba(64, 158, 255);
        border-radius: 4px 4px 0px 0px;
        color: #ffffff;
        font-weight: bold;
        font-size: 16px;
        i{
            font-size: 16px;
            font-weight: bold;
        }
    }
    .bc{
        width: 360px;
        border-radius:0px 0px 4px 4px ;
        padding: 10px 10px;
        background: #ffffff;
    }
}
.edit {
    padding-right: 15px;
    border-right: 1px solid rgba(112, 112, 112, 0.1);
    font-size: 16px;
    color: #409eff;
}
.delete {
    font-size: 16px;
    color: #f56c6c;
}
.dialog-footer {
    width: 100%;
    height: 47px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    background: #f8f8f8;
    align-items: center;
    justify-content: center;
}
.labels {
    margin: 0 auto;
    width: 27px;
    height: 20px;
    position: relative;
    border-radius: 2px;
    &::after {
        content: "";
        width: 0;
        height: 0;
        border-radius: 2px;
        border-top: 10px solid transparent;
        border-right: 8px solid #fff;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 0;
        left: 19px;
    }
}
/deep/.el-color-picker {
    line-height: 60px;
}
/deep/.el-color-picker__color {
    border: none;
}
/deep/.el-color-picker__trigger {
    margin: 0 auto;
    width: 35px;
    height: 20px;
    position: relative;
    border-radius: 2px;
    padding: 0;
    border: none;
    &::after {
        content: "";
        width: 0;
        height: 0;
        border-radius: 2px;
        border-top: 10px solid transparent;
        border-right: 8px solid #fff;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 0;
        right: 0px;
    }
}
.bigLabels {
    margin-right: 13px;
    padding: 0px 15px 0px 6px;
    height: 20px;
    line-height: 20px;
    position: relative;
    border-radius: 2px;
    color: #ffffff;
    margin-top: 4px;
    &::after {
        content: "";
        width: 0;
        height: 0;
        border-radius: 2px;
        border-top: 10px solid transparent;
        border-right: 8px solid #fff;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 0;
        right: 0px;
    }
}
.fl-jus {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
    .flex{
        display: flex;
        align-items: center;
    }
.label {
    margin-right: 8px;
    width: 126px;
    height: 42px;
    background: #fafafa;
    line-height: 42px;
    color: #000000;
    font-weight: 600;
    text-align: center;
}
.label2 {
    width: 145px;
    height: 36px;
    background: #fafafa;
    line-height: 36px;
    color: #000000;
    font-weight: 600;
    text-align: center;
}
.label3 {
    width: 478px;
    height: 36px;
    background: #fafafa;
    line-height: 36px;
    color: #000000;
    font-weight: 600;
    text-align: center;
}
.cont {
    width: 220px;
    height: 36px;
    text-align: center;
    line-height: 36px;
}
.bj{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 28px;
    background: #F79951;
    opacity: 1;
    cursor: pointer;
    color: #FFFFFF;
    font-weight: bold;
    border-radius: 4px;
}
</style>
